import { useState } from 'react';
import cx from 'classnames';
import Modal from '../Modal';
import fonts from 'styles/typography.module.scss';
import MaskotHeart from 'images/maskot_heart.png';
import s from './WelcomeModal.module.scss';

export default function WelcomeModal() {
  const [rememberSelection, setRememberSelection] = useState(false);
  const [hideWelcomeScreen, setHideWelcomeScreen] = useState(
    localStorage.getItem('hideWelcome') === 'true',
  );

  const handleClose = (remember = false) => {
    if (remember) localStorage.setItem('hideWelcome', 'true');
    setHideWelcomeScreen(true);
  };

  if (hideWelcomeScreen) return <></>;

  return (
    <div className={s.modalScroll}>
      <Modal rootClassName={s.modalRoot} className={s.modalContainer}>
        <div className={s.modalContent}>
          <h4 className={cx(fonts.title2, s.title)}>
            Welcome to Collab.Land’s network of communities
          </h4>
          <p className={s.marginBottom}>
            The #1 tokenized community management tool since 2020!
          </p>
          <p className={s.marginBottom}>
            <strong>
              Collab.Land simplifies membership management with automated
              qualification checks.
            </strong>
          </p>
          <p>
            <strong>
              Utilize tokens on 24+ blockchains, manage staked tokens, implement
              advanced token-gating rules with ease, and more!
            </strong>
          </p>
          <ol>
            <li>
              Create roles in Discord, then return to this page and refresh
            </li>
            <li>
              Review{' '}
              <a
                className={s.underline}
                href="https://help.collab.land/command-center/create-a-tgr/how-to-create-a-tgr"
                target="_blank"
                rel="noopener noreferrer"
              >
                step-by-step directions
              </a>{' '}
              for how to create a token-granted role (TGR)
            </li>
            <li>
              Go to the <strong>Miniapps</strong> tab to supercharge your
              Collab.Land bot with new one-click install miniapps like POAP,
              Role Composition, Kudos, and Guest Pass. Free for a limited time!
            </li>
          </ol>
          <p>
            For Bot Updates:{' '}
            <a
              className={s.underline}
              href="https://discord.gg/collabland"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join our Discord
            </a>{' '}
            and Follow us on{' '}
            <a
              className={s.underline}
              href="https://twitter.com/Collab_Land_"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </p>
          <div className={s.checkboxWrapper}>
            <input
              id="remember"
              type="checkbox"
              checked={rememberSelection}
              onChange={(e) => setRememberSelection(e.target.checked)}
            />
            <label htmlFor="remember">Don't show this again</label>
          </div>
          <div className={s.row}>
            <button
              className={cx(s.button, fonts.button1)}
              onClick={() => handleClose(rememberSelection)}
            >
              Ok, got it
            </button>
          </div>
        </div>
        <img src={MaskotHeart} className={s.maskot} alt="logo icon" />
      </Modal>
    </div>
  );
}
