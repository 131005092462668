import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import useScreenSize from '../../hooks/useScreenSize';
import { imgPlaceholderUrl } from '../../icons';
import NewsTickerBanner from './index';

export const Banners = () => {
  const communityCheck = useGetCurrentCommunity();
  const { community } = communityCheck;
  return community ? (
    <>
      <SubscriptionBanner communityId={community.communityId} />
      <AdBanner communityId={community.communityId} />
    </>
  ) : (
    <></>
  );
};

const SubscriptionBanner = ({ communityId }: { communityId: string }) => {
  const [showNewsTickerBanner, setShowNewsTickerBanner] = useState(true);
  const navigate = useNavigate();
  return (
    <>
      {showNewsTickerBanner && (
        <NewsTickerBanner
          content={
            <p style={{ cursor: 'default' }}>
              Collab.Land{' '}
              <span
                onClick={() => {
                  navigate(`/dashboard/${communityId}/subscription`);
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                data-tracking-info={JSON.stringify({
                  id: 'news-ticker:subscription:click',
                  server_id: communityId,
                })}
              >
                Subscriptions
              </span>{' '}
              are here -- make your choice today!
            </p>
          }
          close={() => setShowNewsTickerBanner(false)}
        />
      )}
    </>
  );
};

const AdBanner = ({ communityId }: { communityId: string }) => {
  const [showAdBanner, setShowAdBanner] = useState(true);
  const screenSize = useScreenSize();
  const isMobileScreen = ['mobile', 'tablet'].includes(screenSize);
  return (
    <>
      {showAdBanner && (
        <NewsTickerBanner
          bgColor={'#F5F1E6'}
          content={
            <div
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSe_u1ADYwCTvOgB7UpWCy2Nw6lxyrQZua0aJ3drbdGdy_rPeg/viewform?usp=sf_link',
                );
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#1A1A40',
                gap: 10,
                fontWeight: 800,
                padding: 4,
              }}
              data-tracking-info={JSON.stringify({
                id: 'ad-banner:defensivedefi:click',
                server_id: communityId,
              })}
            >
              <img
                style={{
                  width: '48px',
                  height: '48px',
                }}
                src={imgPlaceholderUrl}
                alt={'ad icon'}
              />
              {isMobileScreen ? (
                `<Insert your message here> Reach out today to get started.`
              ) : (
                <>
                  <span>
                    Looking to reach admins? Place your message where they will
                    see it!
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      // textDecoration: 'underline',
                    }}
                  >
                    Contact us to learn more!
                  </span>
                </>
              )}
            </div>
          }
          close={() => setShowAdBanner(false)}
        />
      )}
    </>
  );
};
