import { useParams } from 'react-router-dom';
import { useSelector } from '../store/hooks';
import { Community } from '../custom-types';

export type GetCommunityResponses =
  | {
      communityNotLoaded: true;
      thereIsNoCommunity: true;
      community: undefined;
    }
  | {
      communityNotLoaded: true;
      thereIsNoCommunity?: false;
      community: Community;
    }
  | {
      communityNotLoaded: false;
      thereIsNoCommunity?: true;
      community?: undefined;
    };

export default function useGetCurrentCommunity(): GetCommunityResponses {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const isCommunityLoaded = useSelector(
    (state) => state.community.isCommunityLoaded,
  );
  if (isCommunityLoaded) {
    const community = communitiesFromState.find(
      (community) => community.communityId === activeCommunityId,
    );
    return community
      ? {
          communityNotLoaded: true,
          thereIsNoCommunity: false,
          community: community,
        }
      : {
          communityNotLoaded: true,
          thereIsNoCommunity: true,
          community: undefined,
        };
  }
  return {
    communityNotLoaded: false,
  };
}
