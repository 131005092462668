import { useState } from 'react';
import { getBackgroundCheckStatus } from '../store/community/actionCreators';
import useGetCurrentCommunity from './useGetCurrentCommunity';
import useSWR from 'swr';

export default function useCheckTGR(message: string) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const communityCheck = useGetCurrentCommunity();
  const { community: activeCommunity } = communityCheck;
  const { data, error, isLoading } = useSWR('/api/status', () => {
    if (activeCommunity?.pk) {
      getBackgroundCheckStatus(activeCommunity.pk)
        .then((result) => {
          if (!result.allowed) {
            setErrorMessage(message);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  });

  return { isLoading, data, errorMessage, setErrorMessage };
}
