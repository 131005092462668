import { useSelector } from '../store/hooks';
import {
  FeatureIdentifiers,
  PlanDetail,
  SubscriptionStatus,
} from '../store/types';
import { GetCommunityResponses } from './useGetCurrentCommunity';

function normalizePlanFeatures(activePlanIds: string[], plans: PlanDetail[]) {
  const normalizePlan = plans.map((plan) => {
    const newPlan: PlanDetail = { ...plan };
    const childPlanIds = plan.features
      ?.filter((p) => p.identifier === FeatureIdentifiers.PLAN)
      .map((p) => p.metadata);
    if (childPlanIds) {
      const childPlans = plans.filter((p) => childPlanIds.includes(p.pk));
      if (childPlans) {
        childPlans.forEach((cp) => {
          newPlan.features = newPlan.features?.concat(cp.features ?? []);
        });
      }
    }
    return newPlan;
  });
  const activePlans = normalizePlan.filter((p) => activePlanIds.includes(p.pk));
  return activePlans.map((activePlan) => activePlan.features ?? []).flat();
}

export default function usePlanHook(communityCheck: GetCommunityResponses) {
  const { community } = communityCheck;
  const subscriptions = useSelector(
    (state) => state.pricing.subscription[community?.pk ?? 0],
  );
  const plans = useSelector((state) => state.pricing.plans);
  const filteredPlan = community?.activePlans?.filter(
    (p) => p.startsWith('BOT#PLAN') || p.startsWith('TEL#BOT#PLAN'),
  );
  const isDefaultPlan =
    filteredPlan?.length === 2 &&
    (filteredPlan.includes('BOT#PLAN#DEFAULT') ||
      filteredPlan.includes('TEL#BOT#PLAN#DEFAULT'));
  const defaultPlan = plans?.find(
    (p) => p?.pk === 'BOT#PLAN#DEFAULT' || p?.pk === 'TEL#BOT#PLAN#DEFAULT',
  );
  const activeFeatures = normalizePlanFeatures(community?.activePlans ?? [], [
    ...plans,
  ]);

  function checkIfParentPlanIsActive(planId: string) {
    return !!plans.find((plan) => {
      if (community?.activePlans.includes(plan.pk)) {
        return !!plan.features.find(
          (feature) =>
            feature.identifier === FeatureIdentifiers.PLAN &&
            feature.metadata === planId,
        );
      }
      return false;
    });
  }

  function getTPCAllowedCount() {
    let allowedCount = 1;
    activeFeatures?.forEach((f) => {
      if (
        f.identifier === FeatureIdentifiers.TPC &&
        typeof f.metadata === 'number' &&
        f.metadata > allowedCount
      ) {
        allowedCount = f.metadata;
      }
    });
    return allowedCount;
  }

  function checkActiveSubscription(planId: string) {
    return subscriptions?.find(
      (sub) =>
        sub.sk.startsWith(planId) && sub.status === SubscriptionStatus.ACTIVE,
    );
  }

  function checkFeature(
    identifier: FeatureIdentifiers,
    metadataValue?: string,
  ) {
    return activeFeatures?.find((feature) => {
      if (feature.identifier !== identifier) {
        return false;
      }
      if (metadataValue && feature.metadata) {
        if (Array.isArray(feature.metadata)) {
          return feature.metadata.includes(metadataValue);
        }
        return feature.metadata === metadataValue;
      }
      return true;
    });
  }

  return {
    checkIfParentPlanIsActive,
    checkFeature,
    checkActiveSubscription,
    getTPCAllowedCount,
    activeFeatures,
    plans,
    defaultPlanIfActive: isDefaultPlan && defaultPlan,
  };
}
