import cx from 'classnames';
import s from '../SideMenu.module.scss';
import { Link, useLocation } from 'react-router-dom';
import fonts from '../../../styles/typography.module.scss';
import React from 'react';
import { Platform } from '../../../store/types';
import useGetInfo from '../../../hooks/useGetInfo';
import { MINIAPP_KEYS } from '../../../constants/marketplace';
import Badge from '../../Badge';

export interface SideMenuProps {
  title: string;
  identifier: string;
  platforms: Platform[];
  miniAppKey?: MINIAPP_KEYS;
  customTrackingKey?: string;
  isFeatureAllowed?: boolean;
  isPro?: boolean;
}

export const ROUTES: Record<string, string> = {
  snapshot: '/dashboard/:communityId/snapshot',
  'guest-passes': '/dashboard/:communityId/guest-passes',
  analytics: '/dashboard/:communityId/analytics',
  tipping: '/dashboard/:communityId/tipping',
  'role-composition': '/dashboard/:communityId/role-composition',
  rolesmanager: '/dashboard/:communityId/rolesmanager',
  config: '/dashboard/:communityId/config',
  subscription: '/dashboard/:communityId/subscription',
  tgrs: '/dashboard/:communityId/tgrs',
  tgas: '/dashboard/:communityId/tgas',
  marketplace: '/dashboard/:communityId/marketplace',
};

export const getRoute = (key: string, data?: Record<string, string>) => {
  let route = ROUTES[key];
  if (!route) throw new Error(`Route ${key} not found`);
  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      route = route.replaceAll(`:${key}`, value);
    });
  }
  return route;
};

const useActiveSubPage = () => {
  const location = useLocation();
  const activeSubpage = location.pathname
    .replace('/dashboard/', '')
    .split('/')[1];
  return activeSubpage;
};

export const SideMenuItem = ({
  title,
  identifier,
  platforms,
  miniAppKey,
  customTrackingKey,
  isPro,
  isFeatureAllowed = true,
}: SideMenuProps) => {
  const { community, user } = useGetInfo();
  const activeSubpage = useActiveSubPage();
  if (!community) return <></>;

  const miniAppCheck = miniAppKey
    ? community?.miniapps?.includes(miniAppKey)
    : true;
  const isAllowed =
    platforms.includes(user.platform as Platform) &&
    miniAppCheck &&
    isFeatureAllowed;
  return (
    <>
      {isAllowed && (
        <div
          className={cx(s.linkWrapper, {
            [s.active]: activeSubpage === identifier,
          })}
        >
          <Link
            to={getRoute(identifier, {
              communityId: community.communityId,
            })}
            className={cx(s.link, fonts.title4)}
            data-tracking-info={JSON.stringify({
              id: `page-link:${customTrackingKey ?? identifier}:click`,
              server_id: community.communityId,
            })}
          >
            {title} {isPro && <Badge className={s.badge}>Pro</Badge>}
          </Link>
        </div>
      )}
    </>
  );
};
