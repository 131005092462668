import {
  Community,
  CryptoWallet,
  Group,
  GuestPass,
  Snapshot,
  Widget,
} from '../custom-types';
import { AnyObject } from 'yup';

export type WalletState = {
  wallets: CryptoWallet[];
};

export type WidgetState = {
  widgets: Widget[];
};

export type GroupState = {
  groups: Group[];
};

export type CommunityState = {
  communityGroups: { items: Community[]; paginationToken: string };
  isCommunityLoaded: boolean;
  selectedRoles: string[];
  selectedRoleCompositionRoles: string[];
  error: string | null;
  loading: boolean;
  showRedditConnectModal: boolean;
};

export type GuestPassState = {
  items: GuestPass[];
  loading: boolean;
  error: string | null;
  activeCommunity: string;
};

export type SnapshotState = {
  items: Snapshot[];
  loading: boolean;
  error: string | null;
};

export type ToastState = {
  toastList: {
    id: string;
    status: string;
    description: string;
  }[];
};

export type UserRole = {
  assigned: string;
  current?: boolean;
  expected?: boolean;
  id?: string;
  name?: string;
  shouldAssign: string;
};

export type RoleManagerState = {
  userRoles: {
    consistent: boolean;
    items: UserRole[];
  };
  loading: boolean;
};

export enum Platform {
  Discord = 'discord',
  Telegram = 'telegram',
  Reddit = 'reddit',
}

export type UserState = {
  id: string;
  username: string;
  platform: string;
  email: string;
  avatar: string;
  iconURL: string;
  accessToken: string;
  is2FAEnabled: boolean;
  connections: string[];
  isUserLoaded: boolean;
  isTokenExpired: boolean;
  name?: string;
  user_profile?: any;
};

export interface Feature {
  identifier: FeatureIdentifiers;
  description: string;
  metadata?: string[] | string | number;
  hide?: boolean;
  subfeatures?: string[];
  isNew?: boolean;
}

export enum FeatureIdentifiers {
  TOGGLE_UPDATES = 'TOGGLE_UPDATES',
  MINIAPP = 'MINIAPP',
  TPC = 'TPC',
  PLAN = 'PLAN',
  ON_DEMAND_BALANCE_CHECK = 'ON_DEMAND_BALANCE_CHECK',
  PREMIUM_SUPPORT = 'PREMIUM_SUPPORT',
  LETS_GO_CUSTOMIZATION = 'LETS_GO_CUSTOMIZATION',
  DONATE_TOGGLE = 'DONATE_TOGGLE',
  BG_BALANCE_CHECK = 'BG_BALANCE_CHECK',
  CUSTOMIZE_DM = 'CUSTOMIZE_DM',
  AND_OR_TPC = 'AND_OR_TPC',
  TELEGRAM_TIPPING = 'TELEGRAM_TIPPING',
}

export type PlanDetail = {
  ribbonText?: string;
  viaContact?: boolean;
  billingPeriod: string;
  classifier: string;
  description: string;
  name: string;
  isPreferred?: boolean;
  pk: string;
  price: number;
  sk: string;
  primaryColor?: string;
  secondaryColor?: string;
  order?: number;
  features: Feature[];
  expirationTime?: number;
  hide?: boolean;
  priceInCLToken: number;
  linkToDocs: string;
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type Subscription = {
  signature?: string;
  address?: string;
  key?: object;
  session?: AnyObject;
  type: string;
  provider: string;
  status: SubscriptionStatus;
  isCancelled?: boolean;
  cancelOn?: number;
  sk: string;
  pk: string;
};

export interface PricingState {
  plans: PlanDetail[];
  subscription: Record<string, Subscription[] | undefined>;
  loading: boolean;
}

export interface PremiumSupportState {
  link?: string;
  loading: boolean;
  code?: string;
}

export type DiscordBotState = {
  [key: string]: {
    responseData: { msg: string; lnk?: string }[];
    isModalShown: boolean;
    showModal: boolean;
    showBanner: boolean;
    checked: boolean;
  };
};
