import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useDispatch, useSelector } from 'store/hooks';
import { clearError, fetchError } from 'store/community/actionCreators';
import TextField from 'components/TextField';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Select from 'components/Select';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { decIntOnly } from 'utils';
import fonts from 'styles/typography.module.scss';
import styles from 'components/SupportForm/SupportForm.module.scss';
import { QuestionIcon } from 'icons';
import { OptionType } from 'components/Select/Select';
import { SupportFormData } from 'custom-types';

type GlobalProps = {
  onSubmit: (body: SupportFormData) => void;
  onCancel: () => void;
  submitLoader: boolean;
};

type ExtraFieldOption = Pick<OptionType, 'label' | 'value'>;

type ExtraFieldsDescriptor =
  | {
      type: 'text' | 'textarea' | 'checkbox';
      name: string;
      label: string;
      value?: string;
      required?: boolean;
      showForIds: string[];
      fieldType?: string;
    }
  | {
      type: 'select' | 'radio';
      name: string;
      label: string;
      required?: boolean;
      options: ExtraFieldOption[];
      showForIds: string[];
      fieldType?: string;
    };

type ExtraFieldTGR = ExtraFieldOption & {
  fieldType: string;
};

type IssueSubCategory = {
  id: string;
  value: string;
  label: string;
  type?: string;
  infoOnly?: string;
};

type IssueCategory = {
  id: string;
  name: string;
  description: string;
  subCategories: IssueSubCategory[];
  extraFields?: ExtraFieldsDescriptor[];
  type?: string;
};

export const SupportForm: React.FC<GlobalProps> = ({
  onSubmit,
  onCancel,
  submitLoader,
}: GlobalProps) => {
  const dispatch = useDispatch();
  const { communityId: activeCommunityId } = useParams();
  const platform = useSelector((state) => state.user.platform);
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );

  const communityFromState = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  );
  const tpcs =
    communityFromState?.tpcs?.map((tpc) => {
      return { label: tpc.name, value: tpc.id };
    }) ?? [];

  const discordCategories: IssueCategory[] = [
    {
      id: '10000000001',
      name: 'role_assignment_issue',
      description: 'Role assignment issue',
      type: 'Problem',
      subCategories: [
        {
          id: '10000000002',
          value: 'member_not_receiving_a_role',
          label: 'Member not receiving a role',
          type: 'Problem',
        },
        {
          id: '10000000003',
          value: 'member_received_role_they_re_not_qualified_for',
          label: "Member received role they're not qualified for",
          type: 'Problem',
        },
        {
          id: '10000000004',
          value: 'role_removed_incorrectly',
          label: 'Role removed incorrectly',
          type: 'Problem',
        },
      ],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: [
            '10000000002',
            '10000000003',
            '10000000004',
            '80000000001',
          ],
        },
        {
          type: 'select',
          name: 'tgr',
          label: 'TGR',
          options: tpcs,
          showForIds: [
            '10000000002',
            '10000000003',
            '10000000004',
            '80000000001',
          ],
        },
      ],
    },
    {
      id: '20000000001',
      name: 'tgr_creation_issue',
      description: 'TGR creation issue',
      type: 'Problem',
      subCategories: [],
      extraFields: [
        {
          type: 'select',
          name: 'tgr',
          label: 'TGR',
          options: tpcs,
          showForIds: ['20000000001'],
        },
      ],
    },
    {
      id: '30000000001',
      name: 'discord_Bot',
      description: 'Discord Bot',
      subCategories: [
        {
          id: '30000000002',
          value: 'bot_paused',
          label: 'Bot paused',
          type: 'Problem',
        },
        {
          id: '30000000003',
          value: 'config_channel_not_being_created',
          label: 'Config channel not being created',
          type: 'Problem',
        },
        {
          id: '30000000004',
          value: 'other',
          label: 'Other',
          type: 'Problem',
        },
      ],
      extraFields: [],
    },
    {
      id: '50000000001',
      name: 'feature_request',
      description: 'Feature request',
      subCategories: [],
      extraFields: [],
      type: 'Feature Request',
    },
    {
      id: '60000000001',
      name: 'report_a_bug',
      description: 'Report a bug',
      subCategories: [
        {
          id: '60000000002',
          value: 'command_center',
          label: 'Command Center',
          type: 'Problem',
        },
        {
          id: '60000000003',
          value: 'discord_Bot',
          label: 'Discord Bot',
          type: 'Problem',
        },
      ],
      extraFields: [],
    },
    {
      id: '70000000001',
      name: 'feedback_or_other_issue',
      description: 'Feedback or other issue',
      type: 'Question',
      subCategories: [],
      extraFields: [],
    },
    {
      id: '80000000001',
      name: 'wallet_connection_issue',
      description: 'Wallet connection issue',
      type: 'Problem',
      subCategories: [],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: ['80000000001'],
        },
      ],
    },
    {
      id: '100000000001',
      name: 'miniapps_issue',
      description: 'Miniapps',
      type: 'Problem',
      subCategories: [
        {
          id: '1000000000010',
          value: 'community_wallet_issue',
          label: 'Community wallet issue',
        },
        {
          id: '1000000000011',
          label: 'Sobol',
          value: 'sobol',
          infoOnly: 'https://support.sobol.io',
        },
        {
          id: '1000000000012',
          label: 'Meme Market',
          value: 'meme_market',
          infoOnly: 'support@meme.market',
        },
        {
          id: '1000000000013',
          label: 'Kchannels',
          value: 'kchannels',
          infoOnly: 'https://kchannels.freshdesk.com/support/tickets/new',
        },
        // {
        //   id: '1000000000014',
        //   label: 'Charmverse',
        //   value: 'charmverse',
        //   infoOnly: ' ',
        // },
        // { id: '1000000000015', label: 'Kudos', value: 'kudos', infoOnly: ' ' },
        {
          id: '1000000000016',
          value: 'other',
          label: 'Other',
        },
      ],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: ['1000000000010'],
        },
      ],
    },
  ];

  const telegramCategories: IssueCategory[] = [
    {
      id: '10000000001',
      name: 'access_issue',
      description: 'Access issue',
      type: 'Problem',
      subCategories: [
        {
          id: '10000000002',
          value: 'members_gaining_access_without_token',
          label: 'Members gaining access without token',
          type: 'Problem',
        },
        {
          id: '10000000003',
          value: 'members_with_token_losing_access',
          label: 'Members with token losing access',
          type: 'Problem',
        },
      ],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: ['10000000002', '10000000003', '80000000001'],
        },
        {
          type: 'select',
          name: 'tga',
          label: 'TGA',
          options: tpcs,
          showForIds: ['10000000002', '10000000003', '80000000001'],
        },
      ],
    },
    {
      id: '20000000001',
      name: 'tga_creation_issue',
      description: 'TGA creation issue',
      type: 'Problem',
      subCategories: [],
      extraFields: [
        {
          type: 'select',
          name: 'tga',
          label: 'TGA',
          options: tpcs,
          showForIds: ['20000000001'],
        },
      ],
    },
    {
      id: '40000000001',
      name: 'telegram_bot',
      description: 'Telegram Bot',
      subCategories: [
        {
          id: '40000000004',
          value: 'not_responding_to_commands',
          label: 'Not responding to commands',
          type: 'Problem',
        },
        {
          id: '40000000005',
          value: 'reset_wallet_association',
          label: 'Reset wallet association',
          type: 'Problem',
        },
        {
          id: '40000000006',
          value: 'other',
          label: 'Other',
          type: 'Problem',
        },
      ],
      extraFields: [],
    },
    {
      id: '50000000001',
      name: 'feature_request',
      description: 'Feature request',
      subCategories: [],
      extraFields: [],
      type: 'Feature Request',
    },
    {
      id: '60000000001',
      name: 'report_a_bug',
      description: 'Report a bug',
      subCategories: [
        {
          id: '60000000002',
          value: 'command_center',
          label: 'Command Center',
          type: 'Problem',
        },
        {
          id: '60000000003',
          value: 'telegram_bot',
          label: 'Telegram Bot',
          type: 'Problem',
        },
      ],
      extraFields: [],
    },
    {
      id: '70000000001',
      name: 'feedback_or_other_issue',
      description: 'Feedback or other issue',
      type: 'Question',
      subCategories: [],
      extraFields: [],
    },
    {
      id: '80000000001',
      name: 'wallet_connection_issue',
      description: 'Wallet connection issue',
      type: 'Problem',
      subCategories: [],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: ['80000000001'],
        },
      ],
    },
  ];

  const redditCategories: IssueCategory[] = [
    {
      id: '10000000001',
      name: 'access_issue',
      description: 'Access issue',
      type: 'Problem',
      subCategories: [
        {
          id: '10000000002',
          value: 'members_gaining_access_without_token',
          label: 'Members gaining access without token',
          type: 'Problem',
        },
        {
          id: '10000000003',
          value: 'members_with_token_losing_access',
          label: 'Members with token losing access',
          type: 'Problem',
        },
      ],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: ['10000000002', '10000000003', '80000000001'],
        },
        {
          type: 'select',
          name: 'tga',
          label: 'TGA',
          options: tpcs,
          showForIds: ['10000000002', '10000000003', '80000000001'],
        },
      ],
    },
    {
      id: '20000000001',
      name: 'tga_creation_issue',
      description: 'TGA creation issue',
      type: 'Problem',
      subCategories: [],
      extraFields: [
        {
          type: 'select',
          name: 'tga',
          label: 'TGA',
          options: tpcs,
          showForIds: ['20000000001'],
        },
      ],
    },
    {
      id: '90000000001',
      name: 'reddit_bot',
      description: 'Reddit Bot',
      subCategories: [],
      extraFields: [],
    },
    {
      id: '50000000001',
      name: 'feature_request',
      description: 'Feature request',
      subCategories: [],
      extraFields: [],
      type: 'Feature Request',
    },
    {
      id: '60000000001',
      name: 'report_a_bug',
      description: 'Report a bug',
      subCategories: [
        {
          id: '60000000002',
          value: 'command_center',
          label: 'Command Center',
          type: 'Problem',
        },
        {
          id: '60000000004',
          value: 'reddit_bot',
          label: 'Reddit Bot',
          type: 'Problem',
        },
      ],
      extraFields: [],
    },
    {
      id: '70000000001',
      name: 'feedback_or_other_issue',
      description: 'Feedback or other issue',
      type: 'Question',
      subCategories: [],
      extraFields: [],
    },
    {
      id: '80000000001',
      name: 'wallet_connection_issue',
      description: 'Wallet connection issue',
      type: 'Problem',
      subCategories: [],
      extraFields: [
        {
          type: 'text',
          name: 'wallet_address',
          label: 'Wallet Address',
          fieldType: 'wallet_address',
          required: false,
          showForIds: ['80000000001'],
        },
      ],
    },
  ];

  let exampleCategories: IssueCategory[] = [];

  switch (platform) {
    case 'telegram':
      exampleCategories = telegramCategories;
      break;
    case 'reddit':
      exampleCategories = redditCategories;
      break;
    default:
      exampleCategories = discordCategories;
      break;
  }

  const [category, setCategory] = useState(exampleCategories[0]);
  const [subCategory, setSubCategory] = useState(category.subCategories[0]);
  const [extraFields, setExtraFields] = useState<ExtraFieldsDescriptor[]>([]);
  const [extraField, setExtraField] = useState<ExtraFieldsDescriptor | null>(
    null,
  );
  const [extraFieldTgr, setExtraFieldTgr] = useState<ExtraFieldTGR | null>(
    null,
  );
  const [email, setEmail] = useState('');
  const [userID, setUserID] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [count, setCount] = useState(0);
  const [showUserID, setShowUserID] = useState(false);
  const [errorTypes, setErrorTypes] = useState({
    email: '',
    userID: '',
    other: '',
    description: '',
  });
  const error = useSelector((state) => state.community.error);
  useEffect(() => {
    setExtraFields(category.extraFields!);

    return () => {
      dispatch(clearError());
    };
  }, [category, extraFields, dispatch]);

  const pkByPlatform = () => {
    let placeholder = '';
    if (platform === 'discord') placeholder = `DIS#COMM#${activeCommunityId}`;
    if (platform === 'telegram') placeholder = `TEL#COMM#${activeCommunityId}`;
    if (platform === 'reddit') placeholder = `RED#COMM#${activeCommunityId}`;
    return placeholder;
  };
  const validateForm = () => {
    setErrorTypes(() => ({
      email: '',
      userID: '',
      other: '',
      description: '',
    }));
    const errors = [];

    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      errors.push('Invalid email');
      setErrorTypes((prevState) => ({
        ...prevState,
        email: 'Invalid email',
      }));
    }

    if (descriptionText.length === 0) {
      errors.push('Description is a required field');
      setErrorTypes((prevState) => ({
        ...prevState,
        description: 'Description is a required field',
      }));
    }

    if (showUserID && userID.length <= 0) {
      errors.push('Please add a valid user ID');
      setErrorTypes((prevState) => ({
        ...prevState,
        userID: 'Please add a valid user ID',
      }));
    }

    if (showUserID && userID.length > 0) {
      if (!decIntOnly.test(userID)) {
        setErrorTypes((prevState) => ({
          ...prevState,
          userID: 'User id should be numbers only.',
        }));
        errors.push('User id should be numbers only.');
      }
    }
    if (extraFields.length > 0) {
      for (let i = 0; i < extraFields.length; i += 1) {
        const extraField = extraFields[i];

        if (
          extraField.required &&
          subCategory.id &&
          extraField.showForIds.includes(subCategory.id)
        ) {
          errors.push(`field ${extraField.label} is required`);
          setErrorTypes((prevState) => ({
            ...prevState,
            other: 'This field is required',
          }));
        }
      }
    }

    if (!errors.length) {
      return true;
    }
    dispatch(fetchError(errors.join('\n')));
    return false;
  };
  const submitHandler = () => {
    let xtraF = {};
    const id = pkByPlatform();
    dispatch(clearError());
    if (validateForm()) {
      const subject = category.description;
      let desc = `${descriptionText}</br>`;

      if (subCategory) desc += `</br>Subcategory: ${subCategory.label}`;
      if (extraField) {
        if (extraField.fieldType === 'wallet_address') {
          xtraF = { ...xtraF, cf_wallet_address: extraField.name };
          desc += `</br>Wallet address: ${extraField.name}`;
        }
      }
      if (extraFieldTgr) {
        xtraF = {
          ...xtraF,
          cf_tgr_id: extraFieldTgr.value,
          // cf_tgr_name: extraFieldTgr.label,
        };
        desc += `</br>Tgr: ${extraFieldTgr.value} - ${extraFieldTgr.label}`;
      }
      if (userID) {
        desc += `</br>Member Discord User ID: ${userID}`;
        xtraF = { ...xtraF, cf_member_discord_user_id: userID };
      }

      const activePlan = communityFromState?.activePlans
        .filter((p) => p.startsWith('BOT#PLAN'))
        .shift();

      onSubmit({
        subject,
        communityId: id,
        description: desc.toString(),
        type:
          category.subCategories.length > 0 ? subCategory.type : category.type,
        custom_fields: xtraF,
        email: email.toString(),
        tags: activePlan ? [activePlan] : [], // Add active plan as tag if available
      });
    }
  };

  const handleTextAreaPlaceholder = () => {
    let placeholder = '';
    if (category.id === '80000000001')
      placeholder =
        'Describe your issue, include the type of wallet you are trying to connect with ie: metamask, ledger, gnosis safe, etc.';
    else if (category.id === '50000000001')
      placeholder = 'Describe the feature you would like to request';
    else placeholder = 'Describe the issue as thoroughly as possible';
    return placeholder;
  };
  let selectTgrLabel = platform === 'discord' ? 'Select TGR' : 'Select TGA';
  if (category.id === '100000000001') selectTgrLabel = 'Select app';

  return (
    <>
      <div className={styles.infoTop}>
        <p className={cx(fonts.title4, styles.formTitle)}>
          What do you need help with?
        </p>
        {/* <QuestionIcon className={styles.infoIcon} title="question icon" /> */}
      </div>
      <div className={styles.roleForm}>
        {error && <p className={cx(styles.errorMsg)}>{error}</p>}
        <div className={styles.formRow}>
          {exampleCategories && (
            <div>
              <p className={cx(styles.label, fonts.title5)}>Select category</p>
              <Select
                onChange={(value) => {
                  setShowUserID(false);
                  setErrorTypes(() => ({
                    email: '',
                    userID: '',
                    other: '',
                    description: '',
                  }));
                  const currentCat = exampleCategories.find(
                    (c) => c.id === value,
                  );
                  setCategory(currentCat!);
                  if (
                    currentCat?.subCategories &&
                    currentCat.subCategories.length > 0
                  ) {
                    setSubCategory({
                      id: currentCat.subCategories[0].id,
                      value: currentCat.subCategories[0].value,
                      label: currentCat.subCategories[0].label,
                      infoOnly: currentCat.subCategories[0].infoOnly,
                    });
                  } else {
                    setSubCategory({
                      id: currentCat?.id!,
                      value: currentCat?.name!,
                      label: currentCat?.description!,
                    });
                  }
                }}
                placeholder="Select Category"
                options={exampleCategories.map((cat) => ({
                  value: cat.id,
                  label: cat.description,
                }))}
                defaultValue={{
                  value: category.id,
                  label: category.description,
                  isDisabled: false,
                }}
              />
            </div>
          )}
          <div>
            <div className={styles.infoTopAlt}>
              <p className={cx(fonts.caption2, styles.infoText)}>
                Email address
              </p>
              <span>(Required)</span>
              <div
                data-tooltip-content="Enter a valid email address. All responses to this support request will be sent via email"
                data-tooltip-id="tooltip-email"
              >
                <QuestionIcon
                  className={styles.infoIcon}
                  title="question icon"
                />
              </div>
            </div>
            <TextField
              placeholder="Enter email address"
              label=""
              inputClassName={styles.input}
              value={email ? email.toString() : ''}
              error={errorTypes.email}
              onChange={(value) => setEmail(value)}
              className={styles.generalInput}
              name="name"
            />
            <ReactTooltip id="tooltip-email" place="right" variant="dark" />
          </div>
          <div>
            <p className={cx(styles.label, fonts.title5)}>Select subcategory</p>
            <Select
              onChange={(value) => {
                setErrorTypes(() => ({
                  email: '',
                  userID: '',
                  other: '',
                  description: '',
                }));
                const findSub = category.subCategories.find(
                  (c) => c.value === value,
                );
                if (findSub) {
                  setSubCategory({
                    id: findSub.id,
                    value: findSub.value,
                    label: findSub.label,
                    infoOnly: findSub.infoOnly,
                  });
                }
              }}
              placeholder="Select subcategory"
              options={
                category.subCategories && category.subCategories.length > 0
                  ? category.subCategories.map((subCat) => ({
                      value: subCat.value,
                      label: subCat.label,
                    }))
                  : [{ value: category.name, label: category.description }]
              }
              defaultValue={
                subCategory
                  ? {
                      value: subCategory.value,
                      label: subCategory.label,
                    }
                  : undefined
              }
            />
          </div>
          {category.extraFields &&
            category.extraFields.length > 0 &&
            extraFields.map((field) => (
              <div>
                {field.type === 'text' &&
                  (field.showForIds.includes(subCategory.id) ||
                    field.showForIds.includes(category.id)) && (
                    <TextField
                      placeholder={field.label}
                      label={field.label}
                      inputClassName={styles.input}
                      value={field.value!}
                      error={errorTypes.other}
                      required={field.required}
                      onChange={(value) =>
                        setExtraField({
                          type: field.type,
                          name: value,
                          label: field.label,
                          required: field.required,
                          fieldType: field.fieldType,
                          showForIds: field.showForIds,
                        })
                      }
                      className={styles.generalInput}
                      name={field.name}
                    />
                  )}
                {field.type === 'select' &&
                  (field.showForIds.includes(subCategory.id) ||
                    field.showForIds.includes(category.id)) && (
                    <>
                      <p className={cx(styles.label, fonts.title5)}>
                        {field.label}
                      </p>
                      <Select
                        onChange={(value) => {
                          setErrorTypes(() => ({
                            email: '',
                            userID: '',
                            other: '',
                            description: '',
                          }));
                          const selection = field.options.find(
                            (option) => option.value === value,
                          );
                          setExtraFieldTgr({
                            ...selection!,
                            fieldType: field.name,
                          });
                        }}
                        placeholder={selectTgrLabel}
                        options={field.options.map((fieldOption) => {
                          return {
                            value: fieldOption.value,
                            label: fieldOption.label,
                          };
                        })}
                      />
                    </>
                  )}
              </div>
            ))}
          {category.id === '10000000001' && (
            <div className={styles.checkBoxContainerTop}>
              <div className={styles.checkBoxContainer}>
                <p className={cx(styles.label, fonts.title5)}>
                  Submit issue on behalf of member
                </p>
                <Checkbox
                  onChange={setShowUserID}
                  id={category.id}
                  checked={showUserID}
                  label=""
                />
              </div>
            </div>
          )}
          {showUserID && (
            <div>
              <TextField
                placeholder="Enter user ID"
                label="User ID"
                inputClassName={styles.input}
                value={userID ? userID.toString() : ''}
                error={errorTypes.userID}
                onChange={(value) => setUserID(value)}
                className={styles.generalInput}
                name="name"
                required={category.id !== '10000000001'}
              />
            </div>
          )}
        </div>
        <div className={styles.infoTop}>
          <p className={cx(fonts.caption2, styles.infoText)}>
            Issue description
          </p>
          {subCategory.infoOnly ? (
            <>
              <div
                data-tooltip-content="Visit the link below to contact the app developers"
                data-tooltip-id="tooltip-issue-description"
              >
                <QuestionIcon
                  className={styles.infoIcon}
                  title="question icon"
                />
              </div>
              <ReactTooltip
                id="tooltip-issue-description"
                place="right"
                variant="dark"
              />
            </>
          ) : (
            <span>(Required)</span>
          )}
        </div>
        {subCategory.infoOnly ? (
          <div className={styles.appContactSection}>
            <span>Please contact the app developers for any issue at:</span>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    subCategory.infoOnly.includes('@')
                      ? `mailto:${subCategory.infoOnly}`
                      : subCategory.infoOnly
                  }
                >
                  {subCategory.infoOnly}
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <>
            <textarea
              className={styles.textarea}
              placeholder={handleTextAreaPlaceholder()}
              value={descriptionText}
              onChange={(e) => {
                const maxAllowedReached = e.target.value.length < 1001;
                if (maxAllowedReached) {
                  setCount(e.target.value.length);
                  setDescriptionText(e.target.value);
                }
              }}
              name="descriptionText"
            />
            <div className={styles.textareaRow}>
              <p className={cx(fonts.caption2, styles.infoText)}>
                <span
                  className={count <= 999 ? styles.countGreen : styles.countRed}
                >
                  {count}
                </span>
                /1000
              </p>
            </div>
          </>
        )}
      </div>
      <div className={styles.buttonsRow}>
        <Button color="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button
          disabled={submitLoader || Boolean(subCategory.infoOnly)}
          onClick={submitHandler}
        >
          Submit support request
        </Button>
      </div>
    </>
  );
};

export default SupportForm;
