import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store/hooks';
import {
  clearError,
  redditValidateAppCredentials,
  toggleRedditConnectModal,
} from 'store/community/actionCreators';
import useSubreddits from 'hooks/useSubreddits';
import { SelectItemModal } from 'components/SelectRoleModal/SelectRoleModal';
import { Subreddit } from 'custom-types';
import s from './RedditConnectModal.module.scss';

const RedditConnectModal = () => {
  const dispatch = useDispatch();
  const showRedditConnectModal = useSelector(
    (state) => state.community.showRedditConnectModal,
  );

  const [submitLoading, setSubmitLoading] = useState(false);

  const { subreddits, loading } = useSubreddits();
  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const existingIds = communitiesFromState?.map((c) => c.communityId) ?? [];
  const filteredSubreddits = subreddits.filter((item) => {
    return !existingIds.includes(item.id);
  });

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const handleSubmit = (subreddit: Subreddit) => {
    const body = {
      targetSubredditName: subreddit.name,
      targetSubredditId: subreddit.id,
    };
    setSubmitLoading(true);

    dispatch(
      redditValidateAppCredentials(body, (err) => {
        setSubmitLoading(false);
        if (!err) dispatch(toggleRedditConnectModal());
      }),
    );
  };

  return (
    <>
      {showRedditConnectModal && (
        <SelectItemModal
          title="Connect subreddit"
          data={filteredSubreddits}
          loading={loading || submitLoading}
          close={() => dispatch(toggleRedditConnectModal())}
          callback={handleSubmit}
          renderItem={(item) => item.name}
          emptyState={
            <p className={s.emptyState}>
              No subreddits available, please create one and try again
            </p>
          }
        />
      )}
    </>
  );
};

export default RedditConnectModal;
