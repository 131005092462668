import s from './SideMenu.module.scss';
import { Top } from './components/Top';
import cx from 'classnames';
import fonts from '../../styles/typography.module.scss';
import { Controls } from './components/Controls';
import React from 'react';
import { SideMenuItem, SideMenuProps } from './components/SideMenuItem';
import { MINIAPP_KEYS } from '../../constants/marketplace';
import { Platform } from '../../store/types';
import { enableProBotFeaturesV2 } from '../../constants/features';
import { BottomSection } from './components/BottomSection';

const PREMIUN_MENU_ITEMS: SideMenuProps[] = [
  {
    title: 'Subscriptions',
    identifier: 'subscription',
    platforms: [Platform.Discord, Platform.Telegram],
  },
  // {
  //   title: 'Community Tipping',
  //   identifier: 'tipping',
  //   platforms: [Platform.Discord, Platform.Telegram],
  //   isPro: true,
  // },
  {
    title: 'Guest Pass',
    identifier: 'guest-passes',
    platforms: [Platform.Discord],
    isPro: true,
  },
  {
    title: 'Role Composition',
    identifier: 'role-composition',
    platforms: [Platform.Discord],
    isPro: true,
  },
  {
    title: 'Community Analytics',
    identifier: 'analytics',
    platforms: [Platform.Discord],
    isPro: true,
    isFeatureAllowed: enableProBotFeaturesV2,
  },
];

const CORE_MENU_ITEMS: SideMenuProps[] = [
  {
    title: 'Bot Config / Invite Link',
    identifier: 'config',
    platforms: [Platform.Telegram],
    customTrackingKey: 'bot-config-invite-link',
  },
  {
    title: 'Bot Config',
    identifier: 'config',
    platforms: [Platform.Discord],
    customTrackingKey: 'bot-config',
  },
  {
    title: 'Miniapps',
    identifier: 'marketplace',
    platforms: [Platform.Discord],
  },
  {
    title: 'Role Troubleshooting',
    identifier: 'rolesmanager',
    platforms: [Platform.Discord],
  },
  {
    title: 'Token Gating Rules (TGRs)',
    identifier: 'tgrs',
    platforms: [Platform.Discord],
  },
  {
    title: 'Token Gated Access (TGA)',
    identifier: 'tgas',
    platforms: [Platform.Telegram],
  },
  {
    title: 'Snapshot',
    identifier: 'snapshot',
    platforms: [Platform.Discord],
    miniAppKey: MINIAPP_KEYS.SNAPSHOT,
  },
];

export const SideMenu = () => {
  const premiumMenuItems = PREMIUN_MENU_ITEMS.map((props) => (
    <SideMenuItem key={props.identifier} {...props} />
  ));
  const coreMenuItems = CORE_MENU_ITEMS.map((props) => (
    <SideMenuItem key={props.identifier} {...props} />
  ));
  return (
    <>
      <div className={s.navigation}>
        <Top />
        {premiumMenuItems.length > 0 && (
          <>
            <p className={cx(s.title, fonts.caption1)}>Premium Services</p>{' '}
            {premiumMenuItems}
          </>
        )}
        {coreMenuItems.length > 0 && (
          <>
            <p className={cx(s.title, fonts.caption1)}>Collab.Land Core</p>{' '}
            {coreMenuItems}
          </>
        )}
      </div>
      <BottomSection />
      <Controls />
    </>
  );
};
