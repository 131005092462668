import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';

import { ArrowIcon } from 'icons';
import { DiscordToken } from 'custom-types';
import { getChainNameById } from 'constants/community';

import { createTpc } from 'store/community/actionCreators';

// import createTPCBG from 'images/createTPCBG.png';
import TPCForm from 'components/TPCForm';
import RoleName from 'components/RoleName';
import Spinner from 'components/Spinner';
import NotFound from 'components/NotFound';

import fonts from 'styles/typography.module.scss';
import s from './CreateTpc.module.scss';
import useCurrentUrl from '../../hooks/useCurrentUrl';
import UpgradePlanModal from '../UpgradePlanModal';
import usePlanHook from '../../hooks/usePlanHook';
import { enableProBotFeaturesV1 } from '../../constants/features';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';

const CreateTpc: React.FC = () => {
  const [params] = useSearchParams();
  const groupId = params.get('groupId');
  const { communityId: activeCommunityId, roleId: activeRoleId } =
    useParams() as {
      communityId: string;
      roleId: string;
    };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showBuyModal, setShowBuyModal] = useState(false);
  const currentUrl = useCurrentUrl();
  const [tpcData, setTpcData] = useState<Partial<DiscordToken>>({});
  const loading = useSelector((state) => state.community.loading);
  const communityCheck = useGetCurrentCommunity();
  const { community: communityFromState } = communityCheck;
  const { getTPCAllowedCount } = usePlanHook(communityCheck);

  useEffect(() => {
    if (communityFromState) {
      if (
        communityFromState.tpcs.length +
          communityFromState.roleCompositionTpcs.length >=
        getTPCAllowedCount()
      ) {
        setShowBuyModal(true);
      }
    }
  }, [communityFromState, getTPCAllowedCount]);

  const activeRole = communityFromState?.roles.find(
    (role) => role.id === activeRoleId,
  );
  const platform = useSelector((state) => state.user.platform);

  const submitHandler = (data: DiscordToken) => {
    if (groupId) {
      data.groupId = groupId;
    }
    const tpc = {
      ...data,
      roleId: activeRoleId,
      asset:
        data.tokenType === 'WILD_FOREST'
          ? 'wild-forest:ronin/erc721:0xa1ce53b661be73bf9a5edd3f0087484f0e3e7363'
          : undefined,
    };
    communityFromState &&
      dispatch(
        createTpc(communityFromState.pk, tpc, () =>
          navigate(`..`, { relative: 'path' }),
        ),
      );
  };

  if (!activeRole && platform === 'discord')
    return <NotFound title="Role not found" />;

  return (
    <>
      <main className={s.wrapper}>
        {showBuyModal && enableProBotFeaturesV1 && (
          <UpgradePlanModal
            title={`${
              platform === 'discord' ? 'TGR' : 'TGA'
            } Allowance Exceeded`}
            message={`For additional ${
              platform === 'discord' ? 'TGRs' : 'TGAs'
            }, please upgrade to the next tier`}
            handleClose={() => {
              setShowBuyModal(false);
              navigate(-1);
            }}
            handleSubmit={() => {
              setShowBuyModal(false);
              navigate(
                `/dashboard/${communityFromState?.communityId}/subscription?redirectUri=${currentUrl}`,
              );
            }}
          ></UpgradePlanModal>
        )}
        <div className={s.content}>
          {/* <img src={createTPCBG} alt="bg img" className={s.bg} /> */}
          <div className={s.head}>
            <button
              className={cx(fonts.button1, s.backButton)}
              type="button"
              onClick={() => navigate(`../..`, { relative: 'path' })}
              data-tracking-info={JSON.stringify({
                id:
                  platform === 'discord'
                    ? `button:back-to-tgrs:click`
                    : `button:back-to-tgas:click`,
                server_id: activeCommunityId,
                chain_type: tpcData.chainType,
                token_type: tpcData.tokenType,
              })}
            >
              <ArrowIcon className={s.arrow} title="back icon" />
              {platform === 'discord'
                ? `Back to ${activeRole!.name} role`
                : `Back to TGAs`}
            </button>

            {platform === 'discord' && (
              <div className={s.headRow}>
                <RoleName
                  className={fonts.title5}
                  roleColor={activeRole!.color}
                >
                  {activeRole!.name}
                </RoleName>
              </div>
            )}
          </div>

          <div className={s.formWrapper}>
            {loading && (
              <div className={s.formLoading}>
                <Spinner />
              </div>
            )}
            <TPCForm
              onSubmit={(data) => submitHandler(data)}
              callback={(data) => setTpcData(data)}
            />
          </div>
        </div>

        <aside className={s.aside}>
          <h5 className={cx(fonts.title3, s.asideTitle)}>
            {platform === 'discord' ? 'TGRs' : 'TGAs'}
          </h5>

          <div className={s.tpcItem}>
            <div className={s.tpcItemRow}>
              <p className={cx(s.tpcItemKey, fonts.caption1)}>Description</p>

              <p className={cx(s.tpcItemValue, fonts.caption1)}>
                {tpcData.name}
              </p>
            </div>

            <div className={s.tpcItemRow}>
              <p className={cx(s.tpcItemKey, fonts.caption1)}>Chain type</p>

              <p className={cx(s.tpcItemValue, fonts.caption1)}>
                {tpcData.chainType ? getChainNameById(tpcData.chainType) : null}
              </p>
            </div>

            <div className={s.tpcItemRow}>
              <p className={cx(s.tpcItemKey, fonts.caption1)}>Token type</p>

              <p className={cx(s.tpcItemValue, fonts.caption1)}>
                {tpcData.tokenType}
              </p>
            </div>
          </div>

          {platform === 'telegram' &&
            communityFromState?.tpcs
              .filter(
                (item) =>
                  item.roleId === activeRoleId || item.groupId === groupId,
              )
              .map((tpc) => {
                return (
                  <>
                    <p className={cx(fonts.title4, s.andOperator)}>AND</p>
                    <button
                      key={tpc.sk}
                      className={cx(s.tpcItem)}
                      type="button"
                      onClick={() =>
                        navigate(
                          `/dashboard/${activeCommunityId}/tgas/edit/${encodeURIComponent(
                            tpc.sk!.replace('TPC#', ''),
                          )}`,
                        )
                      }
                    >
                      <div className={s.tpcItemRow}>
                        <p className={cx(s.tpcItemKey, fonts.caption1)}>
                          Description
                        </p>
                        <p className={cx(s.tpcItemValue, fonts.caption1)}>
                          {tpc.name}
                        </p>
                      </div>
                      <div className={s.tpcItemRow}>
                        <p className={cx(s.tpcItemKey, fonts.caption1)}>
                          Chain type
                        </p>
                        <p className={cx(s.tpcItemValue, fonts.caption1)}>
                          {getChainNameById(tpc.chainId.toString())}
                        </p>
                      </div>
                      <div className={s.tpcItemRow}>
                        <p className={cx(s.tpcItemKey, fonts.caption1)}>
                          Token type
                        </p>
                        <p className={cx(s.tpcItemValue, fonts.caption1)}>
                          {tpc.type}
                        </p>
                      </div>
                    </button>
                  </>
                );
              })}
        </aside>
      </main>
    </>
  );
};

export default CreateTpc;
