import * as Tabs from '@radix-ui/react-tabs';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/hooks';

import Button from 'components/Button';
import EditMetadataModal from 'components/EditMetadataModal';
import Select from 'components/Select';
import TextField from 'components/TextField';
import {
  ChainItem,
  chainTypes,
  getAllowListByChainType,
  getExtraFieldDataByTokenType,
  getFlowNftContracts,
  getRoninTokens,
  getStakingContracts,
  tokenTypes,
  tokenTypesWithMetadata,
  tokenTypesWithTokenId,
  twitterAttributes,
} from 'constants/community';
import type { DiscordToken } from 'custom-types';
import { AttentionIcon, CloseIcon, EditIcon, InfoIcon, PlusIcon } from 'icons';
import { clearError, fetchError } from 'store/community/actionCreators';
import fonts from 'styles/typography.module.scss';
import {
  decIntOnly,
  decIntSixDecimals,
  evmContractAddressRE,
  flowContractAddressRE,
  flowContractNameRE,
  flowContractPrefix,
  openSeaCollectionRE,
  rippleRE,
  tokenIdIsUrl,
  tokenIdRE,
  tokenIdValidUrlRE,
} from 'utils';
import useAxieRules from '../../hooks/useAxieRules';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import styles from './TPCForm.module.scss';

const chainTypeGroups = chainTypes.reduce<
  { label: string; options: [ChainItem] }[]
>((res, chain) => {
  if (chain.isDisabled) return res;
  const index = res.findIndex((o) => o.label === chain.dropdownGroup);
  if (index > -1) {
    res[index].options.push(chain);
  } else {
    res.push({ label: chain.dropdownGroup!, options: [chain] });
  }
  return res;
}, []);

const solanaNftFilters = [
  { value: 'creators', label: 'creators' },
  // { value: 'mints', label: 'mints' },
  { value: 'masterEditions', label: 'masterEditions' },
];
// Keeping the following GroupItems for future reference regarding their values
/*
const chainIdsNear: ChainItem[] = [
  { label: 'NEAR', dropdownLabel: 'Mainnet', value: '0', isDisabled: false },
];
*/
type GlobalProps = {
  callback: (token: DiscordToken) => void;
  onSubmit: (token: DiscordToken) => void;
  initialValue?: DiscordToken;
};

enum RuleTypes {
  Balance = 'balance',
  Attributes = 'attributes',
  StreamAttributes = 'streamAttributes',
}

const getEmptyToken = () => ({
  tokenType: null,
  minAmount: '1',
  maxAmount: null,
  address: null,
  filter: null,
  currency: null,
  taxon: null,
  raftTokenId: null,
  otterspaceName: null,
  chainType: null,
  traits: [],
  traitsCondition: 'and',
  traitsId: '',
  key: Date.now(),
});

const initialErrorTypes = {
  name: '',
  chainType: '',
  tokenType: '',
  filter: '',
  collectionName: '',
  address: '',
  tokenSymbol: '',
  traitsId: '',
  minAmount: '',
  maxAmount: '',
  minFlowRate: '',
  streamReceiver: '',
  taxon: '',
  currency: '',
  raftTokenId: '',
  otterspaceName: '',
};

export const TokenForm: React.FC<GlobalProps> = ({
  callback,
  onSubmit,
  initialValue = getEmptyToken(),
}: GlobalProps) => {
  const dispatch = useDispatch();
  const { community } = useGetCurrentCommunity();
  const wildForestToken = [];
  if (
    ['942702267033845790', '1052510037978923028'].includes(
      community?.communityId ?? '',
    )
  ) {
    wildForestToken.push({
      value: 'WILD_FOREST',
      label: 'Wild Forest',
      isDisabled: false,
    });
  }
  const [flowNftContractOptions] = useState(
    getFlowNftContracts().map((f) => ({
      value: f.name!,
      label: f.label || f.name!,
    })),
  );

  const [token, setToken] = useState<DiscordToken>(initialValue);
  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [errorTypes, setErrorTypes] = useState(initialErrorTypes);
  const error = useSelector((state) => state.community.error);
  const [showWarning, setShowWarning] = useState(false);
  const hasTraits = token.traits && token.traits.length > 0;
  const { rules, options } = useAxieRules();
  const [ruleType, setRuleType] = useState<RuleTypes>(() => {
    if (hasTraits) return RuleTypes.Attributes;
    if (token.tokenType === 'ERC777') return RuleTypes.StreamAttributes;
    return RuleTypes.Balance;
  });

  useEffect(() => {
    if (
      token.chainType &&
      ['5', '80001', '8000000000803'].includes(token.chainType)
    )
      setShowWarning(true);
    else setShowWarning(false);
    return () => {
      dispatch(clearError());
    };
  }, [dispatch, token.chainType]);

  const updateUserRoleToken = (fieldKey: string, value: string) => {
    if (fieldKey === 'chainType') {
      const allowList = getAllowListByChainType(value);
      setErrorTypes(initialErrorTypes);
      dispatch(clearError());
      setToken({ ...token, tokenType: allowList[0], [fieldKey]: value });

      callback({ ...token, tokenType: allowList[0], [fieldKey]: value });

      if (!tokenTypesWithMetadata.includes(allowList[0]))
        setRuleType(RuleTypes.Balance);

      if (value === 'twitter') setRuleType(RuleTypes.Attributes);
    } else if (fieldKey === 'tokenType' && token.chainType === '2020') {
      // Special exception for Ronin tokens
      setToken({ ...token, address: '', [fieldKey]: value });
      callback({ ...token, address: '', [fieldKey]: value });
    } else if (fieldKey === 'tokenType' && value === 'SOLANA_NFT') {
      // Special exception to auto-select the first available filter
      // for SOLANA_NFT
      setToken({
        ...token,
        filter: solanaNftFilters[0].value,
        [fieldKey]: value,
      });

      callback({
        ...token,
        filter: solanaNftFilters[0].value,
        [fieldKey]: value,
      });
      // Special exception to prepend A. for FLOW tokens
    } else if (
      fieldKey === 'address' &&
      value &&
      token.chainType === '8000000000001'
    ) {
      const newValue = flowContractPrefix.test(value) ? value : `A.${value}`;

      setToken({ ...token, [fieldKey]: newValue });

      callback({ ...token, [fieldKey]: newValue });

      // special case for Axie rules
    } else if (fieldKey === 'ruleName') {
      const rule = rules?.find((r) => r.name === value);
      setToken({ ...token, ...rule });
      callback({ ...token, ...rule });
    } else {
      if (fieldKey === 'tokenType' && !tokenTypesWithMetadata.includes(value)) {
        if (value === 'ERC777') {
          setRuleType(RuleTypes.StreamAttributes);
        } else {
          setRuleType(RuleTypes.Balance);
        }
      }

      setToken({ ...token, [fieldKey]: value });

      callback({ ...token, [fieldKey]: value });
    }
  };

  const validateMinMaxAmount = (
    amount: string | null,
    type: string,
    key: string,
  ) => {
    let err = '';
    if (amount) {
      if (!decIntOnly.test(amount)) {
        setErrorTypes((prevState) => ({
          ...prevState,
          [key]: `${type} token amount should be a positive number with up to 40 digits and 8 decimals.`,
        }));
        err = `${type} token amount should be a positive number with up to 40 digits and 8 decimals.`;
      }
      if (amount.length > 40) {
        setErrorTypes((prevState) => ({
          ...prevState,
          [key]: `${type} token amount should be up to 40 digits.`,
        }));
        err = `${type} token amount should be up to 40 digits.`;
      }
    }
    return err;
  };

  const validateForm = () => {
    setErrorTypes(initialErrorTypes);
    const errors = [];

    if (!token.chainType) {
      errors.push('Chain type is required.');
      setErrorTypes((prevState) => ({
        ...prevState,
        chainType: 'Chain type is required.',
      }));
    }
    if (
      token.tokenType &&
      ![
        'ROLL',
        'OPEN_SEA',
        'FLOW_NFT',
        'LOOPRING',
        'staking',
        'otterspace',
        'Twitter',
        'AXIE',
        'HUMANITY_SCORE',
        'Bitcoin_Ordinals',
      ].includes(token.tokenType) &&
      !token.address?.trim()
    ) {
      const { address } = getExtraFieldDataByTokenType(token.tokenType);

      // Check if tokenType is not 'Bitcoin_Stamps', If it is 'Bitcoin_Stamps', then address can be empty
      if (token.tokenType !== 'Bitcoin_Stamps') {
        errors.push(`${address.name} is required.`);
        setErrorTypes((prevState) => ({
          ...prevState,
          address: `${address.name} is required.`,
        }));
      }
    }
    if (
      token.tokenType &&
      [
        'ERC20',
        'ERC721',
        'ERC777',
        'ERC1155',
        'BEP20',
        'BEP721',
        'BEP1155',
      ].includes(token.tokenType) &&
      token.address?.trim()
    ) {
      const isValidAddress = evmContractAddressRE.test(token.address.trim());
      if (!isValidAddress) {
        errors.push('Contract address is invalid.');
        setErrorTypes((prevState) => ({
          ...prevState,
          address: 'Contract address is invalid.',
        }));
      }
    }
    if (token.tokenType === 'FLOW_NFT' && !token.collectionName) {
      errors.push('Contract name is required.');
      setErrorTypes((prevState) => ({
        ...prevState,
        collectionName: 'Contract name is required.',
      }));
    }
    if (token.tokenType === 'staking' && !token.collectionName) {
      errors.push('Contract name is required.');
      setErrorTypes((prevState) => ({
        ...prevState,
        collectionName: 'Contract name is required.',
      }));
    }
    if (token.tokenType === 'OPEN_SEA' && token.collectionName) {
      const isValidCollection = openSeaCollectionRE.test(token.collectionName);
      if (!isValidCollection) {
        errors.push('Collection name is invalid.');
        setErrorTypes((prevState) => ({
          ...prevState,
          collectionName: 'Collection name is invalid.',
        }));
      }
    }
    if (token.tokenType === 'Bitcoin_Ordinals' && !token.collectionName) {
      errors.push('Collection name is required.');
      setErrorTypes((prevState) => ({
        ...prevState,
        collectionName: 'Collection name is required.',
      }));
    }
    if (token.tokenType === 'ERC777') {
      const isAddress =
        token.streamReceiver &&
        evmContractAddressRE.test(token.streamReceiver.trim());
      if (!isAddress) {
        errors.push('Receiver address is invalid.');
        setErrorTypes((prevState) => ({
          ...prevState,
          streamReceiver: 'Receiver address is invalid.',
        }));
      }

      if (
        token.minFlowRate &&
        (!decIntSixDecimals.test(token.minFlowRate) ||
          Number(token.minFlowRate) < 0)
      ) {
        errors.push(
          'Min Flow Rate / Month amount should be a positive number with up to 40 digits and 6 decimals.',
        );
        setErrorTypes((prevState) => ({
          ...prevState,
          minFlowRate:
            'Min Flow Rate / Month amount should be a positive number with up to 40 digits and 6 decimals.',
        }));
      }
    }

    if (token.tokenType === 'FLOW_FT') {
      if (!token.collectionName) {
        errors.push('Public path is required.');
        setErrorTypes((prevState) => ({
          ...prevState,
          collectionName: 'Public path is required.',
        }));
      } else if (!flowContractNameRE.test(token.collectionName)) {
        errors.push(
          'Public path has to start with a letter or an underscore and can contain letters, numbers and underscores.',
        );
        setErrorTypes((prevState) => ({
          ...prevState,
          collectionName:
            'Public path has to start with a letter or an underscore and can contain letters, numbers and underscores.',
        }));
      }
      if (token.address && flowContractAddressRE.test(token.address)) {
        errors.push("Conctract address can't contain whitespace.");
        setErrorTypes((prevState) => ({
          ...prevState,
          address: "Conctract address can't contain whitespace.",
        }));
      }
    }

    if (token.tokenType === 'XRPL_FT') {
      if (!token.currency) {
        errors.push('Currency is required.');
        setErrorTypes((prevState) => ({
          ...prevState,
          currency: 'Currency is required.',
        }));
      }
      if (token.address && !rippleRE.test(token.address)) {
        errors.push('Invalid Issuer Address.');
        setErrorTypes((prevState) => ({
          ...prevState,
          address: 'Invalid Issuer Address.',
        }));
      }
    }

    if (token.tokenType === 'Bitcoin_Stamps') {
      if (!token.address && !token.traitsId) {
        errors.push('Either Creator Address or Token IDs must be provided.');
        setErrorTypes((prevState) => ({
          ...prevState,
          address:
            'Creator Address is optional, but required if Token IDs are not provided.',
          traitsId:
            'Token IDs are optional, but required if Creator Address is not provided.',
        }));
      } else if (!token.address) {
        // If creatorAddress is not provided, use a dummy address
        token.address = 'bc1qpwrxn9ecf3vvc6j8tz993punlvs78rj47d4ee5';
      }
    }

    if (token.tokenType === 'otterspace') {
      if (!token.raftTokenId) {
        errors.push('Raft token ID is required.');
        setErrorTypes((prevState) => ({
          ...prevState,
          raftTokenId: 'Raft token ID is required.',
        }));
      }
      if (!token.otterspaceName) {
        errors.push('Badge name is required.');
        setErrorTypes((prevState) => ({
          ...prevState,
          otterspaceName: 'Badge name is required.',
        }));
      }
    }

    if (token.tokenType === 'ROLL' && !token.tokenSymbol) {
      errors.push('Token symbol is required.');
      setErrorTypes((prevState) => ({
        ...prevState,
        tokenSymbol: 'Token symbol is required.',
      }));
    }
    // Custom validation rule for ERC1155 tokens
    // They need to have at least one token ID included
    if (
      token.tokenType &&
      ['ERC1155', 'BEP1155'].includes(token.tokenType) &&
      !token.traitsId?.trim()
    ) {
      errors.push(
        'At least one token ID is required when using ERC1155 tokens.',
      );
      setErrorTypes((prevState) => ({
        ...prevState,
        traitsId:
          'At least one token ID is required when using ERC1155 tokens.',
      }));
    }

    if (
      token.tokenType &&
      token.traitsId &&
      tokenTypesWithTokenId.includes(token.tokenType) &&
      token.traitsId.trim()
    ) {
      if (token.traitsId && tokenIdIsUrl(token.traitsId.trim())) {
        if (!tokenIdValidUrlRE.test(token.traitsId.trim())) {
          errors.push('Url must be of type http, https or ifps.');
          setErrorTypes((prevState) => ({
            ...prevState,
            traitsId: 'Url must be of type http, https or ipfs.',
          }));
        }
      }

      if (!tokenIdIsUrl(token.traitsId.trim())) {
        if (tokenIdRE.test(token.traitsId.trim())) {
          errors.push(
            "Token ID can't contain any of the following characters: / : ?",
          );
          setErrorTypes((prevState) => ({
            ...prevState,
            traitsId:
              "Token ID can't contain any of the following characters: / : ?",
          }));
        }
      }
    }

    if (token.name) {
      if (token.name.length > 100) {
        errors.push(
          'Description character length should not exceed 100 characters.',
        );
        setErrorTypes((prevState) => ({
          ...prevState,
          name: 'Description character length should not exceed 100 characters.',
        }));
      }
    }

    const minAmountError = validateMinMaxAmount(
      token.minAmount,
      'Min',
      'minAmount',
    );
    const maxAmountError = validateMinMaxAmount(
      token.maxAmount,
      'Max',
      'maxAmount',
    );

    if (minAmountError !== '') errors.push(minAmountError);
    if (maxAmountError !== '') errors.push(maxAmountError);

    if (Number(token.maxAmount) > 0) {
      if (!token.minAmount)
        errors.push(
          'Minimum token amount is required when a maximum amount is set.',
        );
      if (Number(token.minAmount) > Number(token.maxAmount))
        errors.push(
          "Minimum token amount can't be greater than maximum token amount.",
        );
    }

    if (
      ruleType === RuleTypes.Attributes &&
      !token.minAmount &&
      token.traits &&
      token.traits.length === 0
    ) {
      errors.push('Metadata attributes or minimum amount is required.');
    }
    if (!errors.length) {
      return true;
    }
    dispatch(fetchError(errors.join('\n')));
    return false;
  };

  const resetHandler = () => {
    setToken(initialValue);
  };

  const submitHandler = () => {
    dispatch(clearError());
    if (validateForm()) {
      // Pass an empty traits array if the user clicks
      // save with the Balance type selected
      if (ruleType === RuleTypes.Balance) {
        const tokenWithoutTraits = { ...token, traits: [] };
        onSubmit(tokenWithoutTraits);
        return;
      }
      onSubmit(token);
    }
  };

  const removeMetadataCell = (traitIdx: number) => {
    setToken({
      ...token,
      traits: token.traits?.filter((_, index) => index !== traitIdx),
    });
  };

  const allowedTokenTypes = getAllowListByChainType(token.chainType!);

  const { address, unit, tokenId, collectionName } =
    getExtraFieldDataByTokenType(token.tokenType!);

  const selectedFlowContract = token.collectionName
    ? getFlowNftContracts().find((c) => c.name === token?.collectionName)
    : null;

  const stakingContractOptions = useMemo(() => {
    return getStakingContracts()
      .filter((c) => c?.chainId?.toString() === token.chainType)
      .map((f) => ({
        value: f.contractName!,
        label: `${f.contractName}\n${f.contractAddress}`,
      }));
  }, [token.chainType]);

  const selectedStakingContract = token.collectionName
    ? (getStakingContracts().find((c) => {
        return c.contractName === token?.collectionName;
      }) as ReturnType<typeof getStakingContracts> & {
        stakingAssetName: string;
        contractName: string;
        contractAddress: string;
      })
    : null;

  const isTwitter = token.chainType === 'twitter';
  return (
    <div className={styles.roleForm}>
      {showWarning && (
        <div className={cx(styles.banner, styles.warning)}>
          <AttentionIcon
            className={styles.attentionIcon}
            title="attention icon"
          />
          <p>The selected chain is a testnet.</p>
        </div>
      )}
      {error && <p className={cx(styles.errorMsg)}>{error}</p>}
      <TextField
        placeholder="Description"
        label="Description"
        inputClassName={styles.input}
        value={token.name ? token.name.toString() : ''}
        error={errorTypes.name}
        onChange={(value) => updateUserRoleToken('name', value)}
        className={styles.addressInput}
        name="name"
      />
      {errorTypes.chainType !== '' ? (
        <div className={styles.errorLabel}>
          <p className={cx(styles.label, fonts.title5)}>Chain Type</p>
          <InfoIcon className={styles.inlineErrorMsgInfo} title="info icon" />
        </div>
      ) : (
        <p className={cx(styles.label, fonts.title5)}>Chain Type</p>
      )}

      <Select
        onChange={(value) => updateUserRoleToken('chainType', value)}
        className={errorTypes.chainType !== '' ? styles.selectError : undefined}
        options={chainTypes
          .filter((chainType) => !chainType.isDisabled)
          .map((chainType) => ({
            value: chainType.value,
            label: chainType.label,
            isDisabled: chainType.isDisabled,
          }))}
        groupedOptions={chainTypeGroups}
        defaultValue={
          token.chainType
            ? chainTypes.find((item) => item.value === token.chainType)
            : null
        }
      />
      {/* twitter & axie */}
      {token.chainType &&
        !['twitter', '8000000002020', '8000000002001'].includes(
          token.chainType,
        ) && (
          <>
            <p className={cx(styles.label, fonts.title5)}>Token Type</p>
            <Select
              onChange={(value) => updateUserRoleToken('tokenType', value)}
              placeholder="Select type"
              options={tokenTypes
                .filter((tokenType) =>
                  allowedTokenTypes.includes(tokenType.value),
                )
                .map((tokenType) => ({
                  value: tokenType.value,
                  label: tokenType.label,
                  isDisabled: tokenType.isDisabled,
                }))
                .concat(wildForestToken)}
              defaultValue={
                token.tokenType
                  ? tokenTypes.find((item) => item.value === token.tokenType)
                  : null
              }
            />
          </>
        )}
      {token.tokenType === 'SOLANA_NFT' && (
        <>
          <p className={cx(styles.label, fonts.title5)}>Filters</p>
          <Select
            onChange={(value) => updateUserRoleToken('filter', value)}
            placeholder="Input collection name"
            options={solanaNftFilters}
            defaultValue={
              solanaNftFilters.find((add) => add.value === token?.filter) ||
              solanaNftFilters[0]
            }
          />
        </>
      )}

      {token.tokenType === 'FLOW_NFT' && (
        <>
          {errorTypes.collectionName !== '' ? (
            <div className={styles.errorLabel}>
              <p className={cx(styles.label, fonts.title5)}>Flow Contract</p>
              <InfoIcon
                className={styles.inlineErrorMsgInfo}
                title="info icon"
              />
            </div>
          ) : (
            <p className={cx(styles.label, fonts.title5)}>Flow Contract</p>
          )}
          <Select
            onChange={(value) => updateUserRoleToken('collectionName', value)}
            className={
              errorTypes.collectionName !== '' ? styles.selectError : undefined
            }
            placeholder="Select contract"
            options={flowNftContractOptions}
            defaultValue={flowNftContractOptions.find(
              (c) => c.value === token?.collectionName,
            )}
          />
          {token.collectionName && !selectedFlowContract && (
            <p className={styles.caption1}>
              Unknown selected contract name:{' '}
              <span className={styles.errorCaption}>
                {token.collectionName}
              </span>
            </p>
          )}
          {token.collectionName && (
            <>
              <table className={styles.infoTable}>
                <tr>
                  <th>Path:</th>
                  <td>{selectedFlowContract?.path}</td>
                </tr>
                <tr>
                  <th>Address:</th>
                  <td>{selectedFlowContract?.address || token.address}</td>
                </tr>
                <tr>
                  <th>Collection:</th>
                  <td>
                    {selectedFlowContract?.collection || token.collectionName}
                  </td>
                </tr>
              </table>
            </>
          )}
        </>
      )}

      {token.tokenType === 'staking' && (
        <>
          {errorTypes.collectionName !== '' ? (
            <div className={styles.errorLabel}>
              <p className={cx(styles.label, fonts.title5)}>Staking Contract</p>
              <InfoIcon
                className={styles.inlineErrorMsgInfo}
                title="info icon"
              />
            </div>
          ) : (
            <p className={cx(styles.label, fonts.title5)}>Staking Contract</p>
          )}
          <Select
            onChange={(value) => updateUserRoleToken('collectionName', value)}
            className={cx(
              styles.customSelect,
              errorTypes.collectionName !== '' && styles.selectError,
            )}
            placeholder="Select contract"
            hasCustomSingleValue
            options={stakingContractOptions}
            defaultValue={stakingContractOptions.find((c) => {
              return c.value === token?.collectionName;
            })}
          />
          {token.collectionName && !selectedStakingContract && (
            <p className={styles.caption1}>
              Unknown selected contract name:{' '}
              <span className={styles.errorCaption}>
                {token.collectionName}
              </span>
            </p>
          )}
          {token.collectionName && (
            <>
              <table className={styles.infoTable}>
                <tbody>
                  <tr>
                    <th>Contract Name:</th>
                    <td>
                      {selectedStakingContract?.contractName.split('-')[0] ||
                        token?.collectionName.split('-')[0]}
                    </td>
                  </tr>
                  {selectedStakingContract?.['stakingAssetName'] && (
                    <tr>
                      <th>Staking Asset:</th>
                      <td>{selectedStakingContract['stakingAssetName']}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Contract Address:</th>
                    <td>
                      {selectedStakingContract?.contractAddress ||
                        token?.address}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </>
      )}
      {token.chainType === '2020' && (
        <>
          <p className={cx(styles.label, fonts.title5)}>
            Token Symbol | Token Name
          </p>
          <Select
            onChange={(value) => {
              updateUserRoleToken('address', value);
            }}
            className={cx(
              styles.customSelect,
              errorTypes.address !== '' && styles.selectError,
            )}
            placeholder="Token Symbol | Select Token Name"
            options={getRoninTokens().filter(
              (val) => val.tokenType === token?.tokenType,
            )}
            value={
              token.address &&
              getRoninTokens()
                .map((t) => t.value)
                .includes(token.address.toString())
                ? getRoninTokens().find(
                    (t) => t.value === token.address!.toString(),
                  )
                : !token.address || token.address === ''
                ? null
                : {
                    value: '',
                    label: '- - Custom Token Address selected - -',
                  }
            }
            defaultValue={
              token.address
                ? getRoninTokens()
                    .filter((val) => val.tokenType === token?.tokenType)
                    .find((c) => c.value === token.address)
                : null
            }
          />
          {token.tokenType && (
            <>
              <div className={cx(styles.roninCustomAddrOr, fonts.title5)}>
                or
              </div>
              <div className={styles.addressRow}>
                <TextField
                  placeholder={address.placeholder}
                  label={'Custom Token Address'}
                  error={errorTypes.address}
                  inputClassName={styles.input}
                  value={token.address ? token.address.toString() : ''}
                  onChange={(value) => updateUserRoleToken('address', value)}
                  className={styles.addressInput}
                  name="address"
                />
              </div>
            </>
          )}
        </>
      )}
      {/* axie */}
      {token.chainType === '8000000002020' && (
        <>
          <p className={cx(styles.label, fonts.title5)}>
            Axie Infinity Rule Name
          </p>
          <Select
            onChange={(value) => {
              updateUserRoleToken('ruleName', value);
            }}
            className={cx(
              styles.customSelect,
              errorTypes.address !== '' && styles.selectError,
            )}
            placeholder="Rule Name"
            options={options}
            value={
              token.name &&
              options?.find((option) => option.value === token?.name)
                ? options?.find((option) => option.value === token?.name)
                : null
            }
          />
        </>
      )}
      {token.tokenType && ['XRPL_FT'].includes(token.tokenType) && (
        <div className={styles.addressRow}>
          <TextField
            placeholder="Input currency"
            label="Currency"
            error={errorTypes.currency}
            inputClassName={styles.input}
            value={token.currency ? token.currency.toString() : ''}
            onChange={(value) => updateUserRoleToken('currency', value)}
            className={styles.addressInput}
            name="currency"
          />
        </div>
      )}

      {token.tokenType && ['XRPL_NFT'].includes(token.tokenType) && (
        <div className={styles.addressRow}>
          <TextField
            placeholder="Input taxon"
            label="Taxon (optional)"
            error={errorTypes.taxon}
            inputClassName={styles.input}
            value={token.taxon ? token.taxon.toString() : ''}
            onChange={(value) => updateUserRoleToken('taxon', value)}
            className={styles.addressInput}
            name="taxon"
          />
        </div>
      )}

      {token.tokenType === 'otterspace' && (
        <>
          <div className={styles.addressRow}>
            <TextField
              placeholder="Input Raft token ID"
              label="Raft token ID"
              error={errorTypes.raftTokenId}
              inputClassName={styles.input}
              value={token?.raftTokenId ?? ''}
              onChange={(value) => updateUserRoleToken('raftTokenId', value)}
              className={styles.addressInput}
              name="raftTokenId"
            />
          </div>
          <div className={styles.addressRow}>
            <TextField
              placeholder="Input Badge name"
              label="Badge name"
              error={errorTypes.otterspaceName}
              inputClassName={styles.input}
              value={token?.otterspaceName ?? ''}
              onChange={(value) => updateUserRoleToken('otterspaceName', value)}
              className={styles.addressInput}
              name="otterspaceName"
            />
          </div>
        </>
      )}

      {token.tokenType &&
        token.chainType &&
        ![
          'ROLL',
          'OPEN_SEA',
          'FLOW_NFT',
          'staking',
          'otterspace',
          'HUMANITY_SCORE',
          'Bitcoin_Ordinals',
        ].includes(token.tokenType) &&
        !['2020', 'twitter', '8000000002020'].includes(token.chainType) && (
          <div className={styles.addressRow}>
            <TextField
              placeholder={address.placeholder}
              label={address.name}
              error={errorTypes.address}
              inputClassName={styles.input}
              value={token.address ? token.address.toString() : ''}
              onChange={(value) => updateUserRoleToken('address', value)}
              className={styles.addressInput}
              name="address"
            />
          </div>
        )}

      {token.tokenType &&
        ['OPEN_SEA', 'FLOW_FT', 'Bitcoin_Ordinals'].includes(
          token.tokenType,
        ) && (
          <div className={styles.addressRow}>
            <TextField
              placeholder={collectionName.placeholder}
              label={collectionName.name}
              error={errorTypes.collectionName}
              inputClassName={styles.input}
              value={token.collectionName ?? ''}
              onChange={(value) => updateUserRoleToken('collectionName', value)}
              className={styles.addressInput}
              name="collectionName"
            />
          </div>
        )}

      {token.tokenType === 'ROLL' && (
        <div className={styles.addressRow}>
          <TextField
            placeholder="Input token name"
            label="Token Symbol"
            error={errorTypes.tokenSymbol}
            inputClassName={styles.input}
            value={token.tokenSymbol ? token.tokenSymbol.toString() : ''}
            onChange={(value) => updateUserRoleToken('tokenSymbol', value)}
            className={styles.addressInput}
            name="tokenSymbol"
          />
        </div>
      )}

      {token.tokenType && tokenTypesWithTokenId.includes(token.tokenType) && (
        <div className={cx(styles.inputNoLabel, styles.addressRow)}>
          <TextField
            placeholder={tokenId.placeholder}
            value={token.traitsId || ''}
            label={tokenId.name}
            error={errorTypes.traitsId}
            warning={
              token.traitsId && token.traitsId.length > 200000
                ? 'warning'
                : undefined
            }
            onChange={(value) => updateUserRoleToken('traitsId', value)}
            inputClassName={styles.input}
            className={styles.inputWrapper}
            name="tokenId"
          />
          {token.traitsId && token.traitsId.length > 200000 && (
            <div className={styles.infoWarning}>
              <InfoIcon className={styles.infoIconWarning} title="info icon" />
              <p className={cx(fonts.caption1)}>
                {`Your token IDs list is too long (${token.traitsId?.length} characters) and may cause issues. Please consider providing a URL pointing to the full list of IDs`}
              </p>
            </div>
          )}
        </div>
      )}

      {token.tokenType &&
        !['POAP', 'gnosis', 'AXIE'].includes(token.tokenType) && (
          <Tabs.Root
            value={ruleType}
            onValueChange={(value: string) => setRuleType(value as RuleTypes)}
          >
            <Tabs.List
              className={cx(styles.tabsList, {
                [styles.tabsBorder]:
                  token.tokenType &&
                  tokenTypesWithMetadata.includes(token.tokenType),
              })}
            >
              {!['ERC777', 'Twitter'].includes(token.tokenType) && (
                <Tabs.Trigger
                  value={RuleTypes.Balance}
                  className={cx(fonts.title4, {
                    [styles.tabsTrigger]:
                      token.tokenType &&
                      tokenTypesWithMetadata.includes(token.tokenType),
                    [styles.singleTab]:
                      token.tokenType &&
                      !tokenTypesWithMetadata.includes(token.tokenType),
                  })}
                >
                  {token.tokenType === 'HUMANITY_SCORE'
                    ? 'Humanity Score'
                    : 'Balance'}
                </Tabs.Trigger>
              )}
              {token.tokenType &&
                tokenTypesWithMetadata.includes(token.tokenType) &&
                token.chainType !== '81457' && (
                  <Tabs.Trigger
                    value={RuleTypes.Attributes}
                    className={cx(styles.tabsTrigger, fonts.title4)}
                  >
                    Attributes
                  </Tabs.Trigger>
                )}
              {token.tokenType === 'ERC777' && (
                <Tabs.Trigger
                  value={RuleTypes.StreamAttributes}
                  className={cx(styles.singleTab, fonts.title4)}
                >
                  Stream Attributes
                </Tabs.Trigger>
              )}
            </Tabs.List>
            <Tabs.Content value={RuleTypes.Balance}>
              {token.tokenType !== 'ERC777' && (
                <div className={styles.amountRow}>
                  <TextField
                    placeholder={`Enter min ${
                      token.tokenType === 'HUMANITY_SCORE'
                        ? 'score'
                        : 'amount of ' + unit
                    }`}
                    label={`${
                      token.tokenType === 'HUMANITY_SCORE'
                        ? 'Min Score'
                        : 'Min Amount'
                    }`}
                    error={errorTypes.minAmount}
                    value={token.minAmount ? token.minAmount.toString() : '1'}
                    onChange={(value) =>
                      updateUserRoleToken('minAmount', value)
                    }
                    inputClassName={styles.input}
                    name="minAmount"
                    className={styles.amountInput}
                  />
                  <TextField
                    placeholder={`Enter max ${
                      token.tokenType === 'HUMANITY_SCORE'
                        ? 'score'
                        : 'amount of ' + unit
                    } (optional)`}
                    label={`${
                      token.tokenType === 'HUMANITY_SCORE'
                        ? 'Max Score (optional)'
                        : `Max Amount of ${unit} (optional)`
                    }`}
                    error={errorTypes.maxAmount}
                    value={token.maxAmount ? token.maxAmount.toString() : ''}
                    onChange={(value) =>
                      updateUserRoleToken('maxAmount', value)
                    }
                    inputClassName={styles.input}
                    className={styles.amountInput}
                    name="maxAmount"
                  />
                </div>
              )}
            </Tabs.Content>

            <Tabs.Content value={RuleTypes.StreamAttributes}>
              <div className={styles.amountRow}>
                <TextField
                  placeholder="Enter stream receiver address"
                  label="Stream receiver"
                  error={errorTypes.streamReceiver}
                  value={
                    token.streamReceiver ? token.streamReceiver.toString() : ''
                  }
                  onChange={(value) =>
                    updateUserRoleToken('streamReceiver', value)
                  }
                  inputClassName={styles.input}
                  name="streamReceiver"
                  className={styles.addressInput}
                />
                <TextField
                  placeholder="Enter min flow rate"
                  label="Min Flow Rate / Month (optional)"
                  error={errorTypes.minFlowRate}
                  value={token.minFlowRate ? token.minFlowRate.toString() : ''}
                  onChange={(value) =>
                    updateUserRoleToken('minFlowRate', value)
                  }
                  inputClassName={styles.input}
                  name="minFlowRate"
                  className={styles.amountInput}
                />
              </div>
            </Tabs.Content>
            {token.chainType !== '81457' && (
              <Tabs.Content value={RuleTypes.Attributes}>
                {token.tokenType &&
                  tokenTypesWithMetadata.includes(token.tokenType) && (
                    <div className={styles.metadataSection}>
                      {!hasTraits && !isTwitter && (
                        <div
                          className={cx(styles.metadataCaption, fonts.caption1)}
                        >
                          <AttentionIcon
                            className={styles.errorMsgIcon}
                            title="warning icon"
                          />
                          You can select specific NFT metadata&nbsp; conditions.
                        </div>
                      )}
                      <div className={styles.metadataRow}>
                        <p className={cx(styles.metadataText, fonts.title5)}>
                          Metadata:
                        </p>
                        <button
                          type="button"
                          className={cx(styles.metadataButton, fonts.button2)}
                          onClick={() => setShowMetadataModal(true)}
                        >
                          {!hasTraits ? (
                            <div>
                              <PlusIcon
                                className={styles.plus}
                                title="add icon"
                              />
                              <p>Add metadata</p>
                            </div>
                          ) : (
                            <div>
                              <EditIcon
                                className={styles.edit}
                                title="edit icon"
                              />

                              <p>Modify traits</p>
                            </div>
                          )}
                        </button>
                      </div>

                      {hasTraits && (
                        <div
                          className={styles.metadataRow}
                          style={{ paddingTop: 10 }}
                        >
                          <p className={cx(styles.metadataText, fonts.title5)}>
                            Condition: {token.traitsCondition?.toUpperCase()}
                          </p>
                        </div>
                      )}

                      {/* {token.traitsId === '' ? ( */}
                      <div className={styles.metadataContent}>
                        {token.traits?.map((trait, index) => (
                          <div
                            className={styles.metadataCell}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`rule-meta-row-${index}`}
                          >
                            <div className={styles.metadataBody}>
                              <p
                                className={cx(
                                  fonts.caption1,
                                  styles.metadataValue,
                                )}
                              >
                                {isTwitter
                                  ? (
                                      twitterAttributes as Record<
                                        string,
                                        string
                                      >
                                    )[trait.name]
                                  : trait.name}
                              </p>

                              <p
                                className={cx(
                                  fonts.caption1,
                                  styles.metadataValue,
                                )}
                              >
                                {trait.value}
                              </p>
                            </div>

                            <button
                              type="button"
                              className={styles.metadataBodyButton}
                              onClick={() => {
                                removeMetadataCell(index);
                              }}
                            >
                              <CloseIcon
                                className={styles.close}
                                title="close icon"
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* ) : (
                  <p
                    className={cx(fonts.caption1, styles.metadataTraitId)}
                    title={token.traitsId}
                  >
                    Token ID:&nbsp;{token.traitsId}
                  </p>
                )} */}
                      {!isTwitter && (
                        <div className={styles.amountRow}>
                          <TextField
                            placeholder={`Enter min amount of ${unit}`}
                            label="Min Amount (optional)"
                            error={errorTypes.minAmount}
                            value={
                              token.minAmount ? token.minAmount.toString() : ''
                            }
                            onChange={(value) =>
                              updateUserRoleToken('minAmount', value)
                            }
                            inputClassName={styles.input}
                            name="minAmount"
                            className={styles.amountInput}
                          />

                          <TextField
                            placeholder={`Enter max amount of ${unit}`}
                            label="Max Amount (optional)"
                            error={errorTypes.maxAmount}
                            value={
                              token.maxAmount ? token.maxAmount.toString() : ''
                            }
                            onChange={(value) =>
                              updateUserRoleToken('maxAmount', value)
                            }
                            inputClassName={styles.input}
                            className={styles.amountInput}
                            name="maxAmount"
                          />
                        </div>
                      )}
                    </div>
                  )}
              </Tabs.Content>
            )}
          </Tabs.Root>
        )}

      <div className={styles.formRow}>
        <Button
          onClick={resetHandler}
          color="secondary"
          className={styles.formReset}
          data-tracking-info={JSON.stringify({
            id: 'form:reset-btn:click',
          })}
        >
          Reset
        </Button>

        <Button
          onClick={submitHandler}
          className={styles.formSubmit}
          data-tracking-info={JSON.stringify({
            id: 'form:save-btn:click',
          })}
        >
          Save
        </Button>
      </div>

      {showMetadataModal && (
        <EditMetadataModal
          isTwitter={isTwitter}
          traits={token.traits ?? []}
          traitsCondition={token.traitsCondition}
          callback={(traits, traitsCondition) =>
            setToken({
              ...token,
              traits,
              traitsCondition,
              minAmount: null,
              maxAmount: null,
            })
          }
          close={() => setShowMetadataModal(false)}
        />
      )}
    </div>
  );
};

export default TokenForm;
