import { ReactNode } from 'react';
import cx from 'classnames';
import s from './Badge.module.scss';

const colors = {
  default: ' #F6C349',
  green: '#62C28B',
};

type BadgeColors = keyof typeof colors;

interface Props {
  className?: string;
  children: ReactNode;
  large?: boolean;
  flicker?: boolean;
  color?: string;
}

export default function Badge({
  className,
  children,
  large,
  flicker,
  color = '#F6C349',
}: Props) {
  return (
    <span
      className={cx(
        className,
        s.container,
        large && s.large,
        flicker && s.flicker,
      )}
      style={{ backgroundColor: color }}
    >
      {children}
    </span>
  );
}
