import s from '../SideMenu.module.scss';
import cx from 'classnames';
import fonts from '../../../styles/typography.module.scss';
import PlatformIcon from '../../PlatformIcon';
import React from 'react';
import useScreenSize from '../../../hooks/useScreenSize';
import useGetInfo from '../../../hooks/useGetInfo';

export const Top = () => {
  const screenSize = useScreenSize();
  const { community: activeCommunity, user } = useGetInfo();
  return (
    <>
      {' '}
      {!activeCommunity ? (
        <div className={s.placeholderInput} />
      ) : (
        <>
          {!['mobile', 'tablet'].includes(screenSize) && (
            <>
              <h5 className={cx(fonts.title3, s.communityName)}>
                {activeCommunity.name}
              </h5>
            </>
          )}
        </>
      )}
      {!['mobile', 'tablet'].includes(screenSize) && (
        <div className={s.communitySubName}>
          <PlatformIcon />
          <p>
            Logged in with <span className={s.subtitle}>{user.platform}</span>
          </p>
        </div>
      )}
    </>
  );
};
