import React, { useState } from 'react';
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import { PlusIcon } from 'icons';
import { TPC } from 'custom-types';
import Button from 'components/Button';
import TpcCard from 'components/TpcCard';
import ViewMetadataModal from 'components/ViewMetadataModal';
import fonts from 'styles/typography.module.scss';
import s from './TgaConfig.module.scss';
import Spinner from '../Spinner';
import { groupBy } from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { TGAQuota } from '../TGRQuota/TGRQuota';
import usePlanHook from '../../hooks/usePlanHook';
import { enableProBotFeaturesV1 } from '../../constants/features';
import UpgradePlanModal from '../UpgradePlanModal';
import useCurrentUrl from '../../hooks/useCurrentUrl';
import { FeatureIdentifiers } from '../../store/types';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';

const getColor = (n: number) => {
  const colors = ['#F6C349', '#9B9EFF', '#A0CDFF', '#92ECB8']; // Define your colors
  const colorsLength = colors.length;

  // Calculate the correct index, wrapping around if necessary
  const index = n % colorsLength;

  return colors[index];
};

const TgaConfig: React.FC = () => {
  const { communityId: activeCommunityId } = useParams() as {
    communityId: string;
  };
  const navigate = useNavigate();
  const loading = useSelector((state) => state.community.loading);
  const [activeTpc, setActiveTpc] = useState<TPC | null>(null);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const communityCheck = useGetCurrentCommunity();
  const { community: communityFromState } = communityCheck;
  const { checkFeature } = usePlanHook(communityCheck);
  const groupedItems = groupBy(communityFromState?.tpcs, (item: any) => {
    return item.roleId.split('_')[1];
  });
  const currentUrl = useCurrentUrl();
  return (
    <>
      {showBuyModal && enableProBotFeaturesV1 && (
        <UpgradePlanModal
          title={`Upgrade`}
          message={`To use AND conditions please upgrade`}
          handleClose={() => {
            setShowBuyModal(false);
          }}
          handleSubmit={() => {
            setShowBuyModal(false);
            navigate(
              `/dashboard/${communityFromState?.communityId}/subscription?redirectUri=${currentUrl}`,
            );
          }}
        ></UpgradePlanModal>
      )}
      <div
        className={cx(
          communityFromState && communityFromState.tpcs.length > 0
            ? s.content
            : s.contentEmpty,
        )}
      >
        <div className={s.headerRow}>
          <h2 className={cx(fonts.title1, s.headerTitle)}>
            Token Granted Access
          </h2>
          <Button
            color="secondary"
            className={s.headerButton}
            onClick={() => navigate(`/dashboard/${activeCommunityId}/tgas/add`)}
          >
            <PlusIcon className={s.plus} title="add icon" />
            TGA
            <div
              data-tooltip-content="Additional TGAs are “OR” condition"
              data-tooltip-id="tooltip-info"
            >
              <HiOutlineInformationCircle
                className={s.infoIcon}
                title="question icon"
              />
            </div>
            <ReactTooltip id="tooltip-info" place="right" variant="dark" />
          </Button>
        </div>
        <div className={s.headerRow}>
          <TGAQuota />
        </div>
        {loading ? (
          <Spinner />
        ) : Object.keys(groupedItems).length > 0 ? (
          <div className={s.container}>
            {Object.entries(groupedItems).map(([groupId, tpcs], i) => (
              <div
                className={s.groupCard}
                style={{
                  // @ts-ignore
                  '--border-color': getColor(i),
                }}
              >
                {tpcs.map((tpc, index) => (
                  <>
                    {index !== 0 && (
                      <p
                        className={cx(fonts.title4, s.operator)}
                        style={{
                          // @ts-ignore
                          '--operator-color': getColor(i),
                        }}
                      >
                        AND
                      </p>
                    )}
                    <TpcCard
                      // @ts-ignore
                      tpc={tpc}
                      key={tpc.sk}
                      showMetadataCallback={(item) => setActiveTpc(item)}
                    />
                  </>
                ))}

                {tpcs.length < 5 && (
                  <button
                    type="button"
                    color="secondary"
                    className={cx(fonts.button1, s.andButton)}
                    onClick={() => {
                      if (checkFeature(FeatureIdentifiers.AND_OR_TPC)) {
                        navigate(
                          `/dashboard/${activeCommunityId}/tgas/add?groupId=${groupId}`,
                        );
                      } else {
                        setShowBuyModal(true);
                      }
                    }}
                  >
                    + AND
                    <div
                      data-tooltip-content="To gain access, members must hold all tokens"
                      data-tooltip-id="tooltip-info"
                    >
                      <HiOutlineInformationCircle
                        className={s.infoIcon}
                        title="question icon"
                      />
                    </div>
                    <ReactTooltip
                      id="tooltip-info"
                      place="right"
                      variant="dark"
                    />
                  </button>
                )}
              </div>
            ))}
            <div
              className={s.groupCard}
              style={{
                // @ts-ignore
                '--border-color': getColor(Object.keys(groupedItems).length),
              }}
            >
              <button
                type="button"
                color="secondary"
                className={cx(fonts.button1, s.orButton)}
                onClick={() =>
                  navigate(`/dashboard/${activeCommunityId}/tgas/add`)
                }
              >
                + TGA
                <div
                  data-tooltip-content="Additional TGAs are “OR” condition"
                  data-tooltip-id="tooltip-info"
                >
                  <HiOutlineInformationCircle
                    className={s.infoIcon}
                    title="question icon"
                  />
                </div>
                <ReactTooltip id="tooltip-info" place="right" variant="dark" />
              </button>
            </div>
          </div>
        ) : (
          <div className={s.empty}>
            <p className={cx(s.emptyText, fonts.paragraph)}>
              You haven’t configured token permission yet. <br />
              Create a TGA to get started!
            </p>
            <Button
              className={s.emptyButton}
              onClick={() =>
                navigate(`/dashboard/${activeCommunityId}/tgas/add`)
              }
            >
              <PlusIcon className={s.plus} title="add icon" /> TGA
            </Button>
          </div>
        )}

        {activeTpc && (
          <ViewMetadataModal
            close={() => setActiveTpc(null)}
            activeTpc={activeTpc}
          />
        )}
      </div>
    </>
  );
};

export default TgaConfig;
