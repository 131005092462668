import React, { ReactNode } from 'react';
import { ButtonProps } from 'components/Button/Button';
import { CloseIcon } from 'icons';

import s from './NewsTickerBanner.module.scss';

type NewsTickerBannerProps = Omit<ButtonProps, 'renderContainer'> & {
  content: ReactNode;
  bgColor?: string;
  close: () => void;
};

const NewsTickerBanner: React.FC<NewsTickerBannerProps> = ({
  content,
  close,
  bgColor = '#f6c349',
}: NewsTickerBannerProps) => (
  <div
    className={s.msgContainer}
    style={{
      // @ts-ignore
      '--bg-color': bgColor,
    }}
  >
    <div className={s.msgHeaderLeft}>
      <p className={s.vitem}>{content}</p>
    </div>
    <div className={s.msgHeaderRight}>
      <button type="button" className={s.closeButton} onClick={close}>
        <CloseIcon className={s.xIcon} title="close alt dark" />
      </button>
    </div>
  </div>
);

export default NewsTickerBanner;
