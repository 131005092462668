import { MouseEvent, ReactNode } from 'react';
import cx from 'classnames';
import { ArrowShortIcon } from 'icons';
import fonts from 'styles/typography.module.scss';
import {
  Root,
  Trigger as RadTrigger,
  Content,
  Item,
} from '@radix-ui/react-dropdown-menu';

import s from './Dropdown.module.scss';

type ItemType = {
  value: string;
  label: string;
};

interface Props<T extends ItemType> {
  className?: string;
  trigger: ReactNode;
  items: T[];
  onChange: (value: T, e: MouseEvent<HTMLDivElement>) => void;
  showArrow?: boolean;
  showInsideArrow?: boolean;
  variant?: 'filter' | 'sort' | 'button' | 'outlined';
  collisionPadding?: number;
}

export default function Dropdown<T extends ItemType>({
  className = '',
  trigger,
  items = [],
  showArrow = true,
  showInsideArrow = false,
  variant = 'filter',
  onChange,
  collisionPadding = 0,
}: Props<T>) {
  return (
    <Root>
      {/* <div className={cx(s.dropdown_trigger, s.sortDropdownWrapper)}>
        <p className={fonts.button1}>
          Sort Roles by <span>{sortOrder.dropdownTitle}</span>
        </p>
      </div> */}
      <RadTrigger className={cx(s.filterButton, fonts.button1)}>
        {variant === 'button' ? (
          <div className={cx(s.dropdown_button, className)}>{trigger}</div>
        ) : (
          <div
            className={cx(
              s.dropdown_trigger,
              variant === 'sort' && s.sortDropdownWrapper,
              variant === 'outlined' && s.dropdown_outlined,
              className,
            )}
          >
            <p className={fonts.button1}>{trigger}</p>
            {showInsideArrow && (
              <ArrowShortIcon className={s.shortButton} title="short icon" />
            )}
          </div>
        )}
        {showArrow && (
          <ArrowShortIcon className={s.shortButton} title="short icon" />
        )}
      </RadTrigger>
      <Content
        className={s.dropdown_content}
        align="start"
        collisionPadding={collisionPadding}
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <div style={{ maxHeight: '270px', overflow: 'auto' }}>
          {items?.map((item) => (
            <Item
              key={item.value}
              style={{ whiteSpace: 'nowrap' }}
              className={cx(fonts.caption1, s.dropdown_button)}
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                onChange(item, e);
              }}
            >
              {item.label}
            </Item>
          ))}
        </div>
      </Content>
    </Root>
  );
}
