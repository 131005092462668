import cx from 'classnames';
import { InfoIcon } from 'icons';
import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Content, Root, Trigger } from '@radix-ui/react-dropdown-menu';

import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import CollapsibleRow from 'components/CollapsibleRow/CollapsibleRow';
import fonts from 'styles/typography.module.scss';
import s from './CustomMessage.module.scss';
import { useDispatch, useSelector } from '../../../store/hooks';
import {
  fetchLetsGoConfig,
  LetsGoConfig,
  publishLetsGoConfig,
  saveLetsGoConfig,
} from '../../../store/letsGoConfig';
import { useParams } from 'react-router-dom';
import Spinner from '../../Spinner';
import Switch from '../../Switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import usePlanHook from '../../../hooks/usePlanHook';
import useGetCurrentCommunity from '../../../hooks/useGetCurrentCommunity';
import { FeatureIdentifiers } from '../../../store/types';
import Modal from '../../Modal';

const emptyCustomMessage = {
  title: '',
  imageUrl: '',
  description: '',
  color: '',
};

type Props = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'onChange'
> & {
  maxLength: number;
  onChange?: (value: string) => void;
};

const TextAreaWithLimit = ({
  className,
  onChange,
  maxLength = 100,
  ...props
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value?.slice(0, maxLength);
    onChange?.(newValue);
  };
  const currentLength = props?.value?.toString()?.length ?? 0;

  return (
    <div>
      <textarea
        {...props}
        className={cx(s.textarea, className)}
        onChange={handleChange}
      />
      <div className={s.remainingCharactersContainer}>
        <p className={cx(fonts.caption2, s.infoTextColor)}>
          {currentLength}/{maxLength}
        </p>
      </div>
    </div>
  );
};

export default function CustomMessage() {
  const { communityId } = useParams();
  const communityCheck = useGetCurrentCommunity();
  const { checkFeature } = usePlanHook(communityCheck);
  const isLetGoCustomizationEnabled = checkFeature(
    FeatureIdentifiers.LETS_GO_CUSTOMIZATION,
  );
  const [saveDialog, setSaveDialog] = useState(false);
  const isDonateToggleEnabled = checkFeature(FeatureIdentifiers.DONATE_TOGGLE);
  const anyFeatureEnable = isDonateToggleEnabled || isLetGoCustomizationEnabled;
  const communityPk = `DIS#COMM#${communityId}`;
  const { config, loading } = useSelector((state) => state.letsGoConfig);
  const [customMessage, setCustomMessage] = useState(
    config ?? emptyCustomMessage,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (communityPk) {
      dispatch(fetchLetsGoConfig(communityPk));
    }
  }, [dispatch, communityId]);

  useEffect(() => {
    if (config) {
      setCustomMessage(config);
    }
  }, [config]);
  const handleSubmit = async (data: LetsGoConfig) => {
    const requestData = {
      ...data,
    };
    if (communityPk) {
      /* handle create */
      dispatch(
        saveLetsGoConfig({
          communityPk,
          formData: requestData,
        }),
      );
    }
  };

  return (
    <CollapsibleRow
      title={
        <div className={s.titleRow}>
          <h6 className={cx(s.titleLabel, fonts.title3)}>Customize Message</h6>
          <Badge large>Pro</Badge>
        </div>
      }
    >
      {saveDialog && (
        <ConfirmationModal
          handleClose={() => {
            setSaveDialog(false);
          }}
          handleSubmit={() => {
            handleSubmit(customMessage);
            setSaveDialog(false);
          }}
        />
      )}
      {loading && (
        <div className={s.pageLoading}>
          <Spinner />
        </div>
      )}
      <h6 className={cx(s.subtitle, fonts.title5)}>Title</h6>
      <div className={cx(s.row, s.gap2)}>
        <div className={s.flex}>
          <TextAreaWithLimit
            maxLength={60}
            placeholder="Verify your assets"
            rows={1}
            disabled={!isLetGoCustomizationEnabled}
            className={s.textarea}
            value={customMessage?.title ?? ''}
            onChange={(title) => setCustomMessage({ ...customMessage, title })}
          />
          <h6 className={cx(s.customMessageTitle, fonts.title5)}>
            Image Url{' '}
            <div
              data-tooltip-content="Recommended size image: 500x500"
              data-tooltip-id="tooltip-info"
            >
              <InfoIcon className={s.infoIcon} title="question icon" />
            </div>
            <ReactTooltip id="tooltip-info" place="right" variant="dark" />
          </h6>
          <TextAreaWithLimit
            maxLength={200}
            placeholder=""
            rows={1}
            disabled={!isLetGoCustomizationEnabled}
            className={s.textarea}
            value={customMessage?.imageUrl ?? ''}
            onChange={(imageUrl) =>
              setCustomMessage({ ...customMessage, imageUrl })
            }
          />
          <h6 className={cx(s.customMessageTitle, fonts.title5)}>
            Description
          </h6>
          <TextAreaWithLimit
            maxLength={200}
            placeholder="This is a read-only connection. Do not share your private keys. We will never ask for your seed phrase. We will never DM you."
            rows={3}
            disabled={!isLetGoCustomizationEnabled}
            className={s.textarea}
            value={customMessage?.description ?? ''}
            onChange={(description) =>
              setCustomMessage({ ...customMessage, description })
            }
          />
          <div className={s.info}>
            <InfoIcon className={s.icon} />
            <p className={cx(fonts.caption2, s.infoTextColor)}>
              Welcome message can include links.
            </p>
          </div>
          <div className={s.optout}>
            <p className={fonts.caption1}>Donate</p>
            <Switch
              isDisabled={!anyFeatureEnable}
              onChange={(donate) => {
                setCustomMessage({ ...customMessage, donateOptOut: !donate });
              }}
              isChecked={!customMessage.donateOptOut}
            />
          </div>
          <div className={cx(fonts.caption1, s.checkboxWrapper)}>
            <p className={fonts.caption1}>Select banner color</p>
            <Root>
              <Trigger disabled={!isLetGoCustomizationEnabled}>
                <div
                  className={s.selectedColor}
                  style={{ backgroundColor: customMessage?.color ?? '#FFF' }}
                />
              </Trigger>
              <Content
                className={s.dropdownContent}
                align="start"
                onCloseAutoFocus={(e) => {
                  e.preventDefault();
                }}
              >
                <HexColorPicker
                  color={customMessage.color}
                  onChange={(color) =>
                    setCustomMessage({ ...customMessage, color })
                  }
                />
              </Content>
            </Root>
          </div>
          <div className={cx(fonts.caption1, s.checkboxWrapper)}>
            <Button
              className={s.resetButton}
              disabled={!anyFeatureEnable}
              color="secondary"
              onClick={() => {
                setCustomMessage(emptyCustomMessage);
                handleSubmit(emptyCustomMessage);
              }}
            >
              Restore to Default
            </Button>
            <Button
              className={s.saveButton}
              disabled={!anyFeatureEnable}
              onClick={() => setSaveDialog(true)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </CollapsibleRow>
  );
}

export type ConfirmationModalProps = {
  handleClose: () => void;
  handleSubmit: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  handleClose,
  handleSubmit,
}) => {
  return (
    <Modal className={s.container}>
      <h4 className={cx(fonts.title2, s.title, s.text)}>Please Confirm</h4>
      <p className={cx(s.text, fonts.body2, s.uninstallText)}>
        Are you sure you want to send a new message to your server?
      </p>
      <p className={cx(s.text, fonts.body2, s.uninstallText)}>
        When a new message is sent, you can delete any previous messages.
      </p>
      <div className={s.buttonsRow}>
        <Button
          className={s.buttonFixWidth}
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button className={s.buttonFixWidth} onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
