import { CommunityWallet, MiniAppManifest } from '@collabland/sdk';
import { AppCategory } from 'constants/marketplace';

export type IconSource = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export type BotFeatureType = {
  id: string;
  title: string;
  color: string;
  patterColor: string;
  icon?: IconSource;
  illustration?: IconSource;
  image?: IconSource;
  preview: IconSource;
  isNotReady?: boolean;
};

export type BotInterfaceType = {
  id: string;
  title: string;
  color: string;
  patterColor: string;
  icon: IconSource;
  isAuth?: boolean;
};

export type BotFeatureItem = {
  id: string;
  message: string;
  media: File | null;
  roles: DiscordRole[];
};

export type Traits = {
  name: string;
  value: string;
  id?: number;
};

export type DiscordToken = {
  tokenType: string | null;
  minAmount: string | null;
  maxAmount: string | null;
  address: string | null;
  filter: string | null;
  currency: string | null;
  taxon: string | null;
  chainType: string | null;
  raftTokenId: string | null;
  otterspaceName: string | null;
  name?: string;
  key: number;
  id?: string;
  sk?: string;
  roleId?: string;
  groupId?: string;
  traitsId?: string;
  tokenSymbol?: string;
  collectionName?: string;
  minFlowRate?: string;
  streamReceiver?: string;
  eventId?: string;
  metadata?: {
    name: string;
    value: string;
  }[];
  traitsCondition?: string;
  traits?: Traits[];
};

export type DiscordRole = {
  roleId: string | null;
  tokens: DiscordToken[];
  key: number;
};

export interface TransitionCallbacks {
  onEnter?(node: HTMLElement): any;

  onEntered?(node: HTMLElement): any;

  onEntering?(node: HTMLElement): any;

  onExit?(node: HTMLElement): any;

  onExited?(node: HTMLElement): any;

  onExiting?(node: HTMLElement): any;
}

export type TransitionComponent = React.ComponentType<
  {
    in?: boolean;
    appear?: boolean;
  } & TransitionCallbacks
>;

export type TransitionType = boolean | TransitionComponent;

export type Widget = {
  id: string;
  url: string;
  address: string;
  amount: number;
  tokenizedUrl: string;
};

export type CryptoWallet = {
  id: string;
  link: string;
};

export type User = {
  id: string;
  username: string;
  email: string;
  avatar: string;
  accessToken: string;
  is2FAEnabled: boolean;
};

export type Feature = {
  message: string;
  media: string;
  networkType: string;
  type: string;
  amount: number;
};

export type Group = {
  id: string;
  title: string;
  platform: string;
  avatar: string;
  members: number;
  type: 'default' | 'custom' | 'dev';
  features?: Feature[];
  integrationList?: string[];
};

export type GraphCell = {
  increase: number;
  decrease: number;
  date: number;
};

export type Statistic = {
  graphCells: GraphCell[];
  members: number;
  membersOld: number;
  userWithToken: number;
  userWithTokenOld: number;
  tokenPerUser: number;
  tokenPerUserOld: number;
  tokenVolume: number;
  tokenVolumeOld: number;
  tokenPrice: number;
  tokenPriceOld: number;
  tokenSpread: number;
  tokenSpreadOld: number;
};

export type Range = {
  key: string;
  displayName: string;
};

export type Metadata = {
  name: string;
  value: string;
};

export type Subreddit = {
  name: string;
  prefixDisplayName: string;
  communityId?: string;
  id: string;
};

export type TPC = {
  type: string | null;
  // status?: 'active'|'inactive';
  minToken: string | null;
  groupId?: string;
  maxToken: string | null;
  streamReceiver: string | null;
  minFlowRate: string | null;
  contractAddress: string | null;
  filter: string | null;
  currency: string | null;
  taxon: string | null;
  raftTokenId: string | null;
  otterspaceName: string | null;
  collectionName: string | null;
  chainId: number;
  roleId: string;
  sk?: string;
  pk?: string;
  id: string;
  name: string;
  tokenSymbol?: string;
  eventId?: string;
  roleColor?: number;
  traits?: {
    name: string;
    value: string;
  }[];
  traitsId?: string;
  traitsCondition?: string;
  variables?: {
    _asset: string;
    _minCount: string;
    _maxCount: string;
    _to?: string;
    _minRate?: string;
    _traits: {
      // eslint-disable-next-line camelcase
      trait_type: string;
      value: string;
    }[];
  };
};

export type RoleCompositionTpc = {
  name: string;
  scheme: string;
  query: string;
  variables: {
    roleComposition: {
      action: 'add' | 'remove';
      condition: Record<string, boolean>;
      operator: 'and' | 'or';
      enforceAction: boolean;
    };
  };
  sk: string;
  pk: string;
  asset: string;
  roleId: string;
};

export type RoleCondition = Record<string, boolean>;
export type RoleCompositionFormData = {
  name: string;
  roleId: string;
  action: string;
  enforceAction: boolean;
  condition: RoleCondition;
  operator: 'and' | 'or';
};

export type Community = {
  id: string;
  pk: string;
  name: string;
  link: string;
  tokenAmountToEnter: number;
  members: number;
  membersInLastDay: number;
  membersDailyGrowth: number;
  messagesInLastDay: number;
  messagesAllTime: number;
  messagesDailyGrowth: number;
  activePlans: string[];
  iconUrl?: string;
  communityId: string;
  welcomeMessage: string;
  serverImage: string;
  isWelcomeMsgEnabled: boolean;
  disableBkgBalCheck: boolean;
  disablePromotionalUpdates?: boolean;
  tpcs: TPC[];
  invalidTpcs: TPC[];
  roleCompositionTpcs: RoleCompositionTpc[];
  stats: {
    memberCount: number | undefined;
  };
  roles: {
    id: string;
    name: string;
    color: number;
  }[];
  wallet?: [CommunityWallet];
  miniapps?: string[];
};

export type GuestPass = {
  scheme: string;
  status: boolean;
  createdTime: Date;
  period: number;
  name: string;
  asset: string;
  platform: string;
  roleSet: {
    name: string;
    color: number;
    id: string;
  }[];
  sk: string;
  id: string;
  pk: string;
  classifier: string;
  type: string;
};

export type Snapshot = {
  id: string;
  createdTime: string;
  pk: string;
  sk: string;
  classifier: string;
  classifierGroup: string;
  channels: [];
  space: string;
};

export interface BaseTpc {
  id?: string;
  pk: string;
  sk: string;
  name: string;
  type: string;
  roleName: string;
  roleColor: number;
  roleId: string;
  chainId: number;

  minToken: number | string;
  maxToken?: string;

  contractAddress?: string;
  tokenId?: string;

  collectionName?: string;
  eventId?: string;
  tokenSymbol?: string;

  asset?: string;
  variables?: any;

  source?: string;
  createdTime?: string;
  version?: string;
}

export enum RuleVersions {
  V1 = '1.0.0',
  V2 = '2.0.0',
  V2_1 = '2.1.0',
}
export interface MockMarketplaceApp {
  appId: string;
  name: string;
  shortName: string;
  developerId: string;
  releasedDate: number;
  version: MarketplaceAppVersion;
  keywords: string[];
  category: 'cc_extension' | 'bot_command';
  website: string;
  shortDescription: string;
  description: string;
  thumbnails?: MarketplaceAppThumbnail[];
  icons?: MarketplaceAppThumbnail[];
  platform: string[];
  type: string;
  price: string;
  isInstalled: boolean;
  isPurchased: boolean;
  isConfigured: boolean;
  installedAt: string;
  installedVersion: string;
  updatedAt: string;
  downloads: number;
}

export interface MarketplaceAppVersion {
  name: string;
  index: number;
  changelog: string;
}

export interface MarketplaceAppThumbnail {
  label: string;
  src: string;
  sizes: string;
}

export interface MarketplaceAppCommandOptionsChoice {
  name: string;
  value: string;
}

export interface MarketplaceAppCommandOptions {
  name: string;
  description: string;
  type: number;
  required: boolean;
  choices: MarketplaceAppCommandOptionsChoice[];
}

export interface MarketplaceAppCommand {
  rootCommand?: string;
  name: string;
  description: string;
  type: number;
  website?: string;
  options: MarketplaceAppCommandOptions[];
}

// This removes the dynamic property { [key: string]: any } from a type
type RemoveIndex<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
    ? never
    : K]: T[K];
};
export interface MarketplaceApp extends RemoveIndex<MiniAppManifest> {
  appId: string;
  name: string;
  thumbnails?: MarketplaceAppThumbnail[];
  icons?: MarketplaceAppThumbnail[];
  metadata?: Record<string, string>;
  commands?: MarketplaceAppCommand[];
  isInstalled?: boolean;
  downloads?: number;
  installedAt?: number;
  updatedAt?: number;
  rootCommand?: string;
  version?: MarketplaceAppVersion;
  installedVersion?: string;
  status?: string;
  category: AppCategory;
}

export type ChannelOption = { label: string; value: string };

export type SnapshotFormData = {
  id?: string;
  space: string;
  channels: ChannelOption[];
};

export type SupportFormData = {
  subject: string;
  communityId: string;
  description: string;
  type: string | undefined;
  custom_fields: Record<string, any>;
  email: string;
  tags: string[];
};
