import { useSelector } from '../store/hooks';
import useGetCurrentCommunity from './useGetCurrentCommunity';

export default function useGetInfo() {
  const user = useSelector((state) => state.user);
  const communityCheck = useGetCurrentCommunity();
  const { community, thereIsNoCommunity, communityNotLoaded } = communityCheck;
  return {
    user,
    community,
    thereIsNoCommunity,
    communityNotLoaded,
  };
}
