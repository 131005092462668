import { useEffect, useState } from 'react';
import { getAixeRulesOptions, getAxieRules } from '../constants/community';
import { TokenPermissionRuleResponse } from '@collabland/sdk';
import { OptionType } from '../components/Select/Select';

export default function useAxieRules() {
  const [rules, setRules] = useState<TokenPermissionRuleResponse[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  useEffect(() => {
    getAxieRules().then((r) => {
      setRules(r ?? []);
      if (r) {
        setOptions(getAixeRulesOptions(r));
      }
    });
  }, []);
  return {
    rules,
    options,
  };
}
