import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import RedditConnectModal from 'components/RedditConnectModal';
import Spinner from 'components/Spinner';
import s from './ServerPage.module.scss';
import useGetInfo from '../../hooks/useGetInfo';
import { Banners } from '../../components/NewsTickerBanner/Banners';
import { SideMenu } from '../../components/SideMenu/SideMenu';
import { getBackgroundCheckStatus } from '../../store/community/actionCreators';
import NotificationBanner from '../../components/NotificationBanner';
import { useSelector } from '../../store/hooks';
import { enableProBotFeaturesV1 } from '../../constants/features';
import UpgradePlanModal from '../../components/UpgradePlanModal';
import useCurrentUrl from '../../hooks/useCurrentUrl';

interface ServerPageInterface {
  visible?: boolean;
}

export const ServerPage: React.FC<ServerPageInterface> = ({
  visible = true,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { community: activeCommunity, communityNotLoaded } = useGetInfo();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const platform = useSelector((state) => state.user.platform);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [contentRef, location.pathname]);

  useEffect(() => {
    if (activeCommunity?.pk) {
      getBackgroundCheckStatus(activeCommunity.pk)
        .then((result) => {
          if (!result.allowed) {
            const t = platform === 'discord' ? 'TGR' : 'TGA';
            setErrorMessage(
              `Please upgrade to a plan that fits your current ${t} needs. All ${t}s that exceed your current plan will be paused until an upgrade is made.`,
            );
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, [activeCommunity]);

  if (!communityNotLoaded) return <Spinner fullPage />;

  return (
    <main className={s.root}>
      <div className={cx(s.main, !visible && s.hidden)}>
        <RedditConnectModal />
        <SideMenu />
      </div>
      <div className={s.content} ref={contentRef}>
        <Banners />
        {errorMessage && (
          <UpgradePlanModal
            title={`${
              platform === 'discord' ? 'TGR' : 'TGA'
            } Allowance Exceeded`}
            message={errorMessage}
            handleClose={() => {
              setErrorMessage(undefined);
            }}
            handleSubmit={() => {
              setErrorMessage(undefined);
              navigate(
                `/dashboard/${activeCommunity?.communityId}/subscription`,
              );
            }}
          ></UpgradePlanModal>
        )}
        <Outlet />
      </div>
    </main>
  );
};
