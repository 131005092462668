import React, { useState } from 'react';
import LogOutModal from '../../LogOutModal';
import { expireToken } from '../../../store/user';
import s from '../SideMenu.module.scss';
import { LeaveIcon, UserCircle } from '../../../icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../../store/hooks';
import cx from 'classnames';
import fonts from '../../../styles/typography.module.scss';
import { getNameInitials } from '../../../utils';
import useGetInfo from '../../../hooks/useGetInfo';

export const Controls = () => {
  const { user } = useGetInfo();
  return (
    <div className={s.control}>
      {(user?.user_profile?.metadata?.avatar && user.platform === 'discord') ||
      (user.iconURL && user.platform === 'reddit') ? (
        <div className={s.userIcon}>
          {user?.user_profile?.metadata?.avatar || user.iconURL ? (
            <img
              alt="user icon"
              src={
                user.iconURL
                  ? user.iconURL
                  : `https://cdn.discordapp.com/avatars/${user.id}/${user?.user_profile?.metadata?.avatar}.png`
              }
            />
          ) : (
            <p className={cx(fonts.title2)}>
              {user.username ? getNameInitials(user.username) : ''}
            </p>
          )}
        </div>
      ) : (
        <div className={s.userIcon}>
          <UserCircle title="user icon" />
        </div>
      )}
      {user.platform === 'discord' ? (
        <h4 className={cx(s.userName, fonts.title4)}>{user.username}</h4>
      ) : (
        <h4 className={cx(s.userName, fonts.title4)}>
          {
            // eslint-disable-next-line
            user['name'] ? user['name'] : ''
          }
        </h4>
      )}
      <LogOutButton />
    </div>
  );
};

const LogOutButton = () => {
  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {showLogOutModal && (
        <LogOutModal
          close={() => {
            setShowLogOutModal(false);
          }}
          callback={() => {
            localStorage.removeItem('collabLandToken');
            dispatch(expireToken());
            navigate('/login', { state: { from: '/' } });
            setShowLogOutModal(false);
          }}
        />
      )}
      <button
        className={s.leave}
        type="button"
        onClick={() => setShowLogOutModal(true)}
        data-tracking-info={JSON.stringify({
          id: 'button:log-out-icon:click',
        })}
      >
        <LeaveIcon className={s.exit} title="exit icon" />
      </button>
    </>
  );
};
