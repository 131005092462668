import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/reducerUtils';
import { addToast } from '../toasts/actionCreators';
import { fetchPlans, fetchSubscriptions, subscribe } from './service';
import { PricingState } from '../types';

export const PRICING_FEATURE_KEY = 'pricing';

const initialPricingState: PricingState = {
  plans: [],
  subscription: {},
  loading: false,
};

export const subscribePlan = createAsyncThunk(
  'pricing/subscribePlan',
  async (
    {
      planId,
      subscriberId,
      redirectUri,
    }: {
      planId: string;
      subscriberId: string;
      redirectUri: string;
    },
    { dispatch },
  ) => {
    try {
      return await subscribe('unlock', planId, {
        subscriberId,
        redirectUri,
      });
    } catch (err: any) {
      dispatch(
        addToast({
          status: 'error',
          description: err.message,
        }),
      );

      throw err;
    }
  },
);

export const preloadPlans = createAsyncThunk('plan/preloadPlans', async () => {
  return fetchPlans();
});

export const preloadSubs = createAsyncThunk(
  'plan/preloadSubs',
  async (communityId: string) => {
    const subs = await fetchSubscriptions(communityId);
    return {
      [communityId]: subs ?? [],
    };
  },
);

const pricingSlice = createSlice({
  name: PRICING_FEATURE_KEY,
  initialState: initialPricingState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subscribePlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(subscribePlan.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(subscribePlan.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(preloadPlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(preloadPlans.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.plans = payload;
    });
    builder.addCase(preloadPlans.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(preloadSubs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(preloadSubs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subscription = {
        ...state.subscription,
        ...payload,
      };
    });
    builder.addCase(preloadSubs.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default pricingSlice.reducer;
