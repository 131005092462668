import { ENABLE_WALLET } from '../../../constants/marketplace';
import s from '../SideMenu.module.scss';
import cx from 'classnames';
import fonts from '../../../styles/typography.module.scss';
import WalletBalance from '../../WalletBalance';
import Button from '../../Button';
import { simpleLogoBlackUrl } from '../../../icons';
import { enableProBotFeatures } from '../../../constants/features';
import SupportModal from '../../SupportModal';
import { Link, useNavigate } from 'react-router-dom';
import { currentVersion } from '../../../constants/releaseNotes';
import React, { useState } from 'react';
import useGetInfo from '../../../hooks/useGetInfo';

export const BottomSection = () => {
  const { community } = useGetInfo();
  const navigate = useNavigate();
  const [showSupportModal, setShowSupportModal] = useState(false);

  if (!community) return <></>;
  return (
    <>
      {ENABLE_WALLET && (
        <div className={s.mobileWalletWrapper}>
          <p className={cx(s.title, fonts.caption1)}>Community wallet</p>
          <WalletBalance />
        </div>
      )}
      <div className={s.supportContainer}>
        <Button
          className={s.donate}
          onClick={() => window.open('https://donate.collab.land/', '_blank')}
          data-tracking-info={JSON.stringify({
            id: 'button:donate:click',
            server_id: community.communityId,
          })}
        >
          <img src={simpleLogoBlackUrl} alt="support icon" />
          Donate
        </Button>
        {/*{enableProBotFeatures && community.communityId && (*/}
        {/*  <Button*/}
        {/*    className={s.premiumSupport}*/}
        {/*    onClick={() =>*/}
        {/*      navigate(`/dashboard/${community.communityId}/premium-support`)*/}
        {/*    }*/}
        {/*    data-tracking-info={JSON.stringify({*/}
        {/*      id: 'button:collabcare:click',*/}
        {/*      server_id: community.communityId,*/}
        {/*    })}*/}
        {/*  >*/}
        {/*    <img src={simpleLogoBlackUrl} alt="support icon" />*/}
        {/*    CollabCare*/}
        {/*  </Button>*/}
        {/*)}*/}
        {showSupportModal && (
          <SupportModal close={() => setShowSupportModal(false)} />
        )}
        <Button
          className={s.support}
          onClick={() => setShowSupportModal(true)}
          data-tracking-info={JSON.stringify({
            id: 'button:support:click',
            server_id: community.communityId,
          })}
        >
          <img src={simpleLogoBlackUrl} alt="support icon" />
          Support
        </Button>
      </div>
      <div className={cx(s.linkWrapper, s.releaseNotesWrapper)}>
        <Link
          to={`/dashboard${
            community.communityId ? `/${community.communityId}` : ''
          }/release-notes`}
          className={s.releaseNotesButton}
          data-tracking-info={JSON.stringify({
            id: 'link:release-notes:click',
            server_id: community.communityId,
          })}
        >
          Release notes {currentVersion ? `- ${currentVersion}` : ''}
        </Link>
      </div>
    </>
  );
};
